<script setup lang="ts">
import { phMagnifyingGlass, phFadersHorizontal } from '@/phosphor-icons';
import { RouteName } from '@/router/route-name';
import { useRouteQuery } from '@vueuse/router';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { FeatureFlagNames } from '@zapscale/types';

const { isEnabled: advancedSearchEnabled } = useFeatureFlag(FeatureFlagNames.inbox_advance_search);

const router = useRouter();
const route = useRoute();
const searchQuery = useRouteQuery<string>('search');
const textQuery = useRouteQuery<string>('text');

const isExpanded = ref(false);

const searchValue = computed(() => {
  if (searchQuery.value) {
    return searchQuery.value;
  } else if (textQuery.value) {
    return textQuery.value;
  }
  return '';
});

const q = ref('');

const selectedSearchType = ref('text');

const onSubmit = () => {
  if (!q.value.trim()) {
    return;
  }
  const fromPath = route.query.from ? route.query.from : route.fullPath;
  const type = route.query.type ? route.query.type : 'customers';

  if (advancedSearchEnabled.value && route.fullPath.includes('/inbox/')) {
    router.replace({
      name: RouteName.InboxSearch,
      params: { id: route.params.id },
      query: { text: q.value, type: selectedSearchType.value },
    });
  } else {
    router.push({ name: RouteName.Search, query: { search: q.value, type: type, from: fromPath } });
  }
};

const search = computed({
  get() {
    return searchValue.value;
  },
  set(value: string) {
    q.value = value;
  },
});

const searchFilters = ref({
  from: '',
  to: '',
  subject: '',
  stext: '',
});

const advancedSearchText = computed({
  get() {
    let text = '';

    if (textQuery.value !== '' && textQuery.value !== undefined && textQuery.value !== null) {
      text = textQuery.value;
    } else {
      if (searchFilters.value.from !== '') {
        text += `From: (${searchFilters.value.from}) `;
      }
      if (searchFilters.value.to !== '') {
        text += `To: (${searchFilters.value.to}) `;
      }
      if (searchFilters.value.subject !== '') {
        text += `Subject: (${searchFilters.value.subject}) `;
      }
      if (searchFilters.value.stext !== '') {
        text += `Words: (${searchFilters.value.stext}) `;
      }
    }

    return text;
  },
  set(value: string) {
    search.value = value;
  },
});

const onAdvancedSearch = () => {
  if (
    !searchFilters.value.stext &&
    !searchFilters.value.subject &&
    !searchFilters.value.from &&
    !searchFilters.value.to
  ) {
    return;
  }
  router.replace({
    name: RouteName.InboxSearch,
    params: { id: route.params.id },
    query: { type: 'advanced', ...searchFilters.value },
  });
};

const resetAdvancedSearch = () => {
  searchFilters.value = {
    from: '',
    to: '',
    subject: '',
    stext: '',
  };
};

watch(
  () => route.query,
  (newQuery, oldQuery) => {
    if (
      oldQuery &&
      newQuery.type !== oldQuery.type &&
      !newQuery.search &&
      !newQuery.text &&
      !newQuery.stext &&
      !newQuery.from &&
      !newQuery.to &&
      !newQuery.subject
    ) {
      resetAdvancedSearch();
      q.value = '';
    }
  },
  { deep: true },
);
</script>

<template>
  <form @submit.prevent="onSubmit">
    <q-input
      v-if="advancedSearchEnabled && route.fullPath.includes('/inbox/')"
      v-model="advancedSearchText"
      placeholder="Search in emails..."
      :class="`tw-rounded-3xl tw-border tw-px-4 ${isExpanded || advancedSearchText !== '' ? '' : ''}`"
      :ripple="false"
      dense
      borderless
    >
      <template v-slot:prepend>
        <q-btn flat round dense @click="isExpanded = !isExpanded" class="-tw-ml-3">
          <q-icon :name="phFadersHorizontal" class="tw-h-6 tw-w-6" />
          <q-menu class="tw-border tw-bg-light-shade-1 tw-shadow" :offset="[215, 10]">
            <div class="tw-w-[28rem] tw-p-4">
              <div class="tw-space-y-3">
                <q-input
                  v-model="searchFilters.from"
                  label="From"
                  dense
                  outlined
                  class="tw-w-full"
                  @keyup.enter="onAdvancedSearch"
                />
                <q-input
                  v-model="searchFilters.to"
                  label="To"
                  dense
                  outlined
                  class="tw-w-full"
                  @keyup.enter="onAdvancedSearch"
                />
                <q-input
                  v-model="searchFilters.subject"
                  label="Subject"
                  dense
                  outlined
                  class="tw-w-full"
                  @keyup.enter="onAdvancedSearch"
                />
                <q-input
                  v-model="searchFilters.stext"
                  label="Has the words"
                  dense
                  outlined
                  class="tw-w-full"
                  @keyup.enter="onAdvancedSearch"
                />
                <div class="tw-flex tw-gap-2">
                  <q-btn
                    color="primary"
                    class="tw-flex-[3]"
                    label="Search"
                    type="submit"
                    @click.prevent="onAdvancedSearch"
                  />
                  <q-btn color="grey-6" outline class="tw-flex-[1]" label="Reset" @click="resetAdvancedSearch" />
                </div>
              </div>
            </div>
          </q-menu>
        </q-btn>
      </template>
      <template v-slot:append>
        <button type="submit" @click.prevent="onSubmit">
          <q-icon :name="phMagnifyingGlass" class="tw-h-4 tw-w-4" />
        </button>
      </template>
    </q-input>

    <q-input
      v-else
      v-model="search"
      placeholder="Search..."
      class="tw-rounded-3xl tw-border tw-px-4"
      :ripple="false"
      dense
      borderless
    >
      <template v-slot:append>
        <button type="submit" @click.prevent="onSubmit">
          <q-icon :name="phMagnifyingGlass" class="tw-h-4 tw-w-4" />
        </button>
      </template>
    </q-input>
  </form>
</template>
