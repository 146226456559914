<script setup lang="ts">
import { MessageSchema } from '@/lib/i18n';
import {
  phPulse,
  phChartLine,
  phChartLineUp,
  phCheckSquareOffset,
  phClock,
  phFile,
  phLayout,
  phMessengerLogo,
  phTrendDown,
} from '@/phosphor-icons';
import { useDashboardV2Store } from '@/stores/dashboardv2';
import { useDateStore } from '@/stores/date';
import { storeToRefs } from 'pinia';
import { useDialogPluginComponent } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ModalTitle from '../ModalTitle.vue';
import MoreDetailsGridItem from '../MoreDetailsGridItem.vue';
import MoreDetailsItem from './MoreDetailsItem.vue';
import { DateTypeEnum } from '@/api/client';
import { useAmountFormatter } from '@/composables/useAmountFormatter';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const { dialogRef, onDialogCancel } = useDialogPluginComponent();
const { dateText, iDate } = storeToRefs(useDateStore());
const { dashboardInfo } = storeToRefs(useDashboardV2Store());
const { formatNumericalValue } = useAmountFormatter();

// const showChurnInfo = computed(() => {
//   if (dashboardFor.value?.id === '-') {
//     return true;
//   }
//   return false;
// });

const tabs = [
  { name: 'customer_user_stats', label: t('customer_user_stats') },
  { name: 'team_stats', label: t('team_stats') },
];

const time = computed(() => {
  switch (iDate.value?.type) {
    case DateTypeEnum.Day:
      return t('day');
    case DateTypeEnum.Week:
      return t('week');
    case DateTypeEnum.Month:
      return t('month');
    case DateTypeEnum.Quarter:
      return t('quarter');
    case DateTypeEnum.Year:
      return t('year');
  }
  return '';
});
const selectedTab = ref(tabs[0].name);

const isValidYoyChurnInfo = computed(() => {
  if (
    dashboardInfo.value?.yoy_churn_percentage != null &&
    dashboardInfo.value?.yoy_churn_percentage_percent_change_from_last != null
  ) {
    return true;
  }
  return false;
});

const isValidQoqChurnInfo = computed(() => {
  if (
    dashboardInfo.value?.qoq_churn_percentage != null &&
    dashboardInfo.value?.qoq_churn_percentage_percent_change_from_last != null
  ) {
    return true;
  }
  return false;
});

const isValidMomChurnInfo = computed(() => {
  if (
    dashboardInfo.value?.mom_churn_percentage != null &&
    dashboardInfo.value?.mom_churn_percentage_percent_change_from_last != null
  ) {
    return true;
  }
  return false;
});
</script>
<template>
  <q-dialog ref="dialogRef" full-height position="right">
    <div class="tw-flex tw-min-w-40 tw-flex-col tw-bg-light-shade-2">
      <ModalTitle :title="t('more_details')" @on-close-button-click="onDialogCancel">
        <template #title_icon>
          <span class="tw-px-2 tw-text-base tw-font-semibold tw-text-dark-shade-2">
            {{ dateText }}
          </span>
        </template>
      </ModalTitle>
      <q-tabs
        v-model="selectedTab"
        align="justify"
        active-color="primary"
        active-bg-color="#E6F1F6"
        class="tw-border-b tw-bg-light-shade-1"
        :breakpoint="0"
        no-caps
        inline-label
        indicator-color="primary"
      >
        <q-tab v-for="tab in tabs" :key="tab.name" v-bind="tab" :ripple="false" exact />
      </q-tabs>

      <q-scroll-area class="tw-h-full tw-flex-grow tw-bg-light-shade-2">
        <div v-if="selectedTab === tabs[0].name">
          <!-- yoy churn section -->
          <MoreDetailsItem v-if="isValidYoyChurnInfo" :colspan="'tw-grid-cols-3'">
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.yoy_churn_percentage_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLineUp" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.yoy_churn_percentage }}%</template>
                <template #footer>{{ t('more_details_modal.yoy_customer_churn') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.yoy_gross_revenue_retention_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLine" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.yoy_gross_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.yoy_grr') }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.yoy_net_revenue_retention_percent_change_from_last">
                <template #header>
                  <q-icon :name="phTrendDown" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.yoy_net_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.yoy_nrr') }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- qoq churn section -->
          <MoreDetailsItem v-if="isValidQoqChurnInfo" :colspan="'tw-grid-cols-3'">
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.qoq_churn_percentage_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLineUp" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.qoq_churn_percentage }}%</template>
                <template #footer>{{ t('more_details_modal.qoq_customer_churn') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.qoq_gross_revenue_retention_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLine" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.qoq_gross_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.qoq_grr') }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.qoq_net_revenue_retention_percent_change_from_last">
                <template #header>
                  <q-icon :name="phTrendDown" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.qoq_net_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.qoq_nrr') }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- mom churn section -->
          <MoreDetailsItem v-if="isValidMomChurnInfo" :colspan="'tw-grid-cols-3'">
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.mom_churn_percentage_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLineUp" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.mom_churn_percentage }}%</template>
                <template #footer>{{ t('more_details_modal.mom_customer_churn') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.mom_gross_revenue_retention_percent_change_from_last"
              >
                <template #header>
                  <q-icon :name="phChartLine" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.mom_gross_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.mom_grr') }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.mom_net_revenue_retention_percent_change_from_last">
                <template #header>
                  <q-icon :name="phTrendDown" size="1.5rem" />
                </template>
                <template #value>{{ dashboardInfo?.mom_net_revenue_retention }}</template>
                <template #footer>{{ t('more_details_modal.mom_nrr') }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- software info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-3'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phClock" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.software_usage') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.total_sessions_time_percent_change_from_last"
              >
                <template #value
                  >{{ formatNumericalValue(dashboardInfo?.total_sessions_time_in_minutes) }}
                  <span class="tw-text-xs tw-text-dark-shade-4">m</span></template
                >
                <template #footer>{{ t('more_details_modal.total_time_of_usage') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.total_session_time_per_customer_percent_change_from_last"
              >
                <template #value>{{
                  formatNumericalValue(dashboardInfo?.total_session_time_per_customer_in_minutes)
                }}</template>
                <template #footer>{{ t('more_details_modal.mins_customer_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.total_session_time_per_user_percent_change_from_last">
                <template #value>{{
                  formatNumericalValue(dashboardInfo?.total_session_time_per_user_in_minutes)
                }}</template>
                <template #footer>{{ t('more_details_modal.mins_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- page info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-3'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phFile" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.pages_visited') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_page_visits_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_page_visits) }}</template>
                <template #footer>{{ t('more_details_modal.total_pages_visited') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_page_visits_customers_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_page_visits_customers) }}</template>
                <template #footer>{{ t('more_details_modal.pages_customer_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_page_visits_users_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_page_visits_users) }}</template>
                <template #footer>{{ t('more_details_modal.pages_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- feature info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-3'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phLayout" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.feature_used') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_feature_used_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_feature_used) }}</template>
                <template #footer>{{ t('more_details_modal.total_features_used') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_feature_used_per_customers_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_feature_used_per_customers) }}</template>
                <template #footer>{{ t('more_details_modal.features_customer_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #third>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_feature_used_per_user_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_feature_used_per_user) }}</template>
                <template #footer>{{ t('more_details_modal.user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- bug info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-2'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phLayout" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.bugs_logged') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_bugs_logged_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_bugs_logged) }}</template>
                <template #footer>{{ t('more_details_modal.no_of_bugs_logged') }}</template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem
                :color-code="''"
                :percentage="dashboardInfo?.no_of_bugs_logged_per_customer_percent_change_from_last"
              >
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_bugs_logged_per_customer) }}</template>
                <template #footer>{{ t('more_details_modal.bugs_customer_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>
        </div>

        <!-- Team stat -->
        <div v-else>
          <!-- Task Info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-2'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phCheckSquareOffset" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.tasks') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_tasks_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_tasks) }}</template>
                <template #footer>{{ t('more_details_modal.total_task_done') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_tasks_per_user_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_tasks_per_user) }}</template>
                <template #footer>{{ t('more_details_modal.tasks_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- Message Info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-2'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phMessengerLogo" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.messages') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_messages_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_messages) }}</template>
                <template #footer>{{ t('more_details_modal.messages_handled') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_messages_per_user_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_messages_per_user) }}</template>
                <template #footer>{{ t('more_details_modal.messages_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- Action Info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-2'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phPulse" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.actions') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_actions_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_actions) }}</template>
                <template #footer>{{ t('more_details_modal.actions_done') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem :percentage="dashboardInfo?.no_of_actions_per_users_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.no_of_actions_per_users) }}</template>
                <template #footer>{{ t('more_details_modal.actions_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>

          <!-- Action Time Info -->
          <MoreDetailsItem :colspan="'tw-grid-cols-2'">
            <template #header>
              <div class="tw-mb-2 tw-flex tw-items-center tw-px-2 tw-text-sm tw-text-dark-shade-3">
                <q-icon :name="phClock" size="1.5rem" />
                <span class="tw-px-1">
                  {{ t('more_details_modal.actions_time') }}
                </span>
              </div>
            </template>
            <template #first>
              <MoreDetailsGridItem :percentage="dashboardInfo?.action_times_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.action_times) }}</template>
                <template #footer>{{ t('more_details_modal.hours_spent') }} </template>
              </MoreDetailsGridItem>
            </template>

            <template #second>
              <MoreDetailsGridItem :percentage="dashboardInfo?.action_times_per_user_percent_change_from_last">
                <template #value>{{ formatNumericalValue(dashboardInfo?.action_times_per_user) }}</template>
                <template #footer>{{ t('more_details_modal.mins_user_time', { time: time }) }}</template>
              </MoreDetailsGridItem>
            </template>
          </MoreDetailsItem>
        </div>
      </q-scroll-area>
    </div>
  </q-dialog>
</template>
