/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { C2DocumentTypes } from '../model/';
// @ts-ignore
import { CreateC2DocumentTypesDto } from '../model/';
/**
 * C2DocumentTypesApi - axios parameter creator
 * @export
 */
export const C2DocumentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create C2 Document Type
         * @param {CreateC2DocumentTypesDto} createC2DocumentTypesDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DocumentTypesControllerCreate: async (createC2DocumentTypesDto: CreateC2DocumentTypesDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createC2DocumentTypesDto' is not null or undefined
            assertParamExists('c2DocumentTypesControllerCreate', 'createC2DocumentTypesDto', createC2DocumentTypesDto)
            const localVarPath = `/api/c2-document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createC2DocumentTypesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of C2 Document Type
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DocumentTypesControllerFindAll: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/c2-document-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * C2DocumentTypesApi - functional programming interface
 * @export
 */
export const C2DocumentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = C2DocumentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create C2 Document Type
         * @param {CreateC2DocumentTypesDto} createC2DocumentTypesDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2DocumentTypesControllerCreate(createC2DocumentTypesDto: CreateC2DocumentTypesDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<C2DocumentTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2DocumentTypesControllerCreate(createC2DocumentTypesDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of C2 Document Type
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async c2DocumentTypesControllerFindAll(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2DocumentTypes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.c2DocumentTypesControllerFindAll(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * C2DocumentTypesApi - factory interface
 * @export
 */
export const C2DocumentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = C2DocumentTypesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create C2 Document Type
         * @param {CreateC2DocumentTypesDto} createC2DocumentTypesDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DocumentTypesControllerCreate(createC2DocumentTypesDto: CreateC2DocumentTypesDto, xTenantId?: string, options?: any): AxiosPromise<C2DocumentTypes> {
            return localVarFp.c2DocumentTypesControllerCreate(createC2DocumentTypesDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of C2 Document Type
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        c2DocumentTypesControllerFindAll(xTenantId?: string, options?: any): AxiosPromise<Array<C2DocumentTypes>> {
            return localVarFp.c2DocumentTypesControllerFindAll(xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for c2DocumentTypesControllerCreate operation in C2DocumentTypesApi.
 * @export
 * @interface C2DocumentTypesApiC2DocumentTypesControllerCreateRequest
 */
export interface C2DocumentTypesApiC2DocumentTypesControllerCreateRequest {
    /**
     * 
     * @type {CreateC2DocumentTypesDto}
     * @memberof C2DocumentTypesApiC2DocumentTypesControllerCreate
     */
    readonly createC2DocumentTypesDto: CreateC2DocumentTypesDto

    /**
     * 
     * @type {string}
     * @memberof C2DocumentTypesApiC2DocumentTypesControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for c2DocumentTypesControllerFindAll operation in C2DocumentTypesApi.
 * @export
 * @interface C2DocumentTypesApiC2DocumentTypesControllerFindAllRequest
 */
export interface C2DocumentTypesApiC2DocumentTypesControllerFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof C2DocumentTypesApiC2DocumentTypesControllerFindAll
     */
    readonly xTenantId?: string
}

/**
 * C2DocumentTypesApi - object-oriented interface
 * @export
 * @class C2DocumentTypesApi
 * @extends {BaseAPI}
 */
export class C2DocumentTypesApi extends BaseAPI {
    /**
     * 
     * @summary Create C2 Document Type
     * @param {C2DocumentTypesApiC2DocumentTypesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DocumentTypesApi
     */
    public c2DocumentTypesControllerCreate(requestParameters: C2DocumentTypesApiC2DocumentTypesControllerCreateRequest, options?: AxiosRequestConfig) {
        return C2DocumentTypesApiFp(this.configuration).c2DocumentTypesControllerCreate(requestParameters.createC2DocumentTypesDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of C2 Document Type
     * @param {C2DocumentTypesApiC2DocumentTypesControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof C2DocumentTypesApi
     */
    public c2DocumentTypesControllerFindAll(requestParameters: C2DocumentTypesApiC2DocumentTypesControllerFindAllRequest = {}, options?: AxiosRequestConfig) {
        return C2DocumentTypesApiFp(this.configuration).c2DocumentTypesControllerFindAll(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
