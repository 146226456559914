import { C1CurrencyEnum } from '@/api/client';
import { useAccountStore } from '@/stores/account';
import { intervalToDuration } from 'date-fns';
import { storeToRefs } from 'pinia';

export const useAmountFormatter = () => {
  const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P', 'E'];

  const { c1 } = storeToRefs(useAccountStore());

  function getCurrencySymbol() {
    switch (c1.value?.c1_currency) {
      case C1CurrencyEnum.Inr:
        return '₹';
      case C1CurrencyEnum.Eur:
        return '€';
      case C1CurrencyEnum.Gbp:
        return '£';
      case C1CurrencyEnum.Usd:
        return '$';
      default:
        return '';
    }
  }

  //! Divide by 100 as discussed with Arnab
  function formatAmount(amount: number | undefined) {
    const currencySymbol = getCurrencySymbol();
    // what tier? (determines SI symbol)
    if (!amount) {
      return currencySymbol + '0';
    }
    const rs = amount / 100;
    const tier = (Math.log10(Math.abs(rs)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return `${currencySymbol}${rs.toFixed(1)}`;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    // scale the number
    const scaled = rs / scale;

    // format number and add suffix
    return `${currencySymbol}${scaled.toFixed(1) + suffix}`;
  }

  function formatNumberRoundOfDecimal(value: number | undefined, decimalPrecision = 0): string {
    if (value === 0) {
      return value.toString();
    }
    if (!value) {
      return '';
    }
    return value.toFixed(decimalPrecision);
  }

  function roundOfPositiveString(value: number | undefined) {
    if (value === undefined || value === null) {
      return '';
    }
    return Math.abs(+value.toFixed(0)) + '%';
  }

  function formatSecondsToHour(value: number | undefined): number | undefined {
    if (value === 0) {
      return value;
    }
    if (!value) {
      return undefined;
    }
    const result = +(value / 3600).toFixed(2);
    return result;
  }

  function formatSeconds(value?: number | undefined): Duration | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (value === 0) {
      return { hours: 0, minutes: 0 } as Duration;
    }
    return intervalToDuration({ start: 0, end: value * 1000 });
  }

  function formatSecondsToMins(value: number | undefined): number | undefined {
    if (value === 0) {
      return value;
    }
    if (!value) {
      return undefined;
    }
    return +(value / 60).toFixed(2);
  }

  function formatActualAmount(amount: number | undefined) {
    const currencySymbol = getCurrencySymbol();
    if (!amount) {
      return `${currencySymbol}0`;
    }
    const remainder = amount % 100;
    let rs: string = '';
    if (remainder > 0) {
      rs = (amount / 100).toFixed(2);
    } else {
      rs = (amount / 100).toFixed(0);
    }
    // insert commas as thousand separator for the amount
    return `${currencySymbol}${rs.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  function formatNumericalValue(val: any) {
    if (val == null || val === '') {
      return '-';
    }

    const isInvalidNumber = isNaN(Number(val));
    if (isInvalidNumber) {
      return val;
    }

    return Intl.NumberFormat(
      'en-US',
      Number(val) % 1 !== 0 ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : {},
    ).format(Number(val));
  }

  return {
    formatAmount,
    roundOfPositiveString,
    formatNumberRoundOfDecimal,
    formatSecondsToHour,
    formatSecondsToMins,
    formatSeconds,
    formatActualAmount,
    getCurrencySymbol,
    formatNumericalValue,
  };
};
