<script setup lang="ts">
import { DateTypeEnum } from '@/api/client';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useColorFormatter } from '@/composables/useColorFormatter';
import { useDateStore } from '@/stores/date';
import { IMoreDetailsElement, MoreDetailsElementType } from '@/types/dashboard';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps<{ data: IMoreDetailsElement }>();

const { iDate } = storeToRefs(useDateStore());

const { setVisibility, getCaretStyle, getCaretType } = useColorFormatter();
const { formatNumberRoundOfDecimal, formatSeconds, formatNumericalValue } = useAmountFormatter();

const isTime = computed(() => {
  return props.data.type === MoreDetailsElementType.TIME;
});

const displayValue = computed(() => {
  const display = props.data.value;
  if (props.data.type === MoreDetailsElementType.PERCENTAGE) {
    return display + '%';
  }
  return display;
});

const totalUsageFontSize = computed(() => {
  let fontSize = '';
  if (!props.data.value) {
    return fontSize;
  }
  switch (iDate.value?.type) {
    case DateTypeEnum.Day:
      fontSize = 'tw-text-[2.5rem] tw-leading-[3.25rem]';
      break;
    case DateTypeEnum.Week:
      fontSize = 'tw-text-4xl tw-leading-[3.25rem]';
      break;
    case DateTypeEnum.Month:
      fontSize = 'tw-text-[2rem] tw-leading-[3.25rem]';
      break;
    case DateTypeEnum.Quarter:
      fontSize = 'tw-text-2xl tw-leading-[3.25rem]';
      break;
    case DateTypeEnum.Year:
      fontSize = 'tw-text-2xl tw-leading-[3.25rem]';
      break;
  }
  return fontSize;
});

const duration = computed(() => {
  return formatSeconds(props.data?.value);
});
</script>

<template>
  <div class="tw-px-4">
    <div class="tw-flex tw-flex-row tw-justify-start" :class="setVisibility(props.data.value)">
      <div class="tw-flex tw-flex-col">
        <div class="tw-mb-2 tw-flex tw-items-center tw-text-sm tw-text-dark-shade-3">
          <q-icon :name="props.data.headerIcon" size="1.5rem" />
          <span v-if="props.data.header" class="tw-px-1">
            {{ props.data.header }}
          </span>
        </div>
        <div
          class="tw-mb-1 tw-flex tw-items-center tw-text-[2.5rem] tw-font-semibold tw-leading-[3.25rem] tw-text-dark-shade-2"
        >
          <div class="tw-flex" v-if="isTime" :class="totalUsageFontSize">
            <span class="tw-flex tw-items-baseline" v-if="duration?.months">
              {{ duration?.months }}<span class="tw-text-xs tw-text-dark-shade-4">m</span></span
            >
            <span class="tw-flex tw-items-baseline" v-if="duration?.weeks">
              {{ duration?.weeks }}<span class="tw-text-xs tw-text-dark-shade-4">w</span></span
            >
            <span class="tw-flex tw-items-baseline" v-if="duration?.days">
              {{ duration?.days }}<span class="tw-text-xs tw-text-dark-shade-4">d</span></span
            >
            <span class="tw-flex tw-items-baseline" v-if="duration?.hours">
              {{ duration?.hours }}<span class="tw-text-xs tw-text-dark-shade-4">h</span></span
            >
            <span class="tw-flex tw-items-baseline">
              {{ duration?.minutes }}<span class="tw-text-xs tw-text-dark-shade-4">m</span></span
            >
          </div>
          <span v-else> {{ formatNumericalValue(displayValue) }} </span>
          <div
            class="tw-ml-1 tw-flex tw-flex-row tw-items-center tw-pt-3"
            :class="setVisibility(props.data.percentage)"
          >
            <q-icon
              :name="getCaretType(props.data.percentage)"
              class="tw-mr-0.5 tw-text-[0.625rem]"
              :class="getCaretStyle(props.data.percentage)"
            />
            <span class="tw-text-sm tw-font-semibold tw-text-dark-shade-2"
              >{{ formatNumberRoundOfDecimal(props.data.percentage) }}%</span
            >
          </div>
        </div>
        <div class="tw-ml-1 tw-mt-1 tw-text-sm tw-font-semibold tw-text-dark-shade-3" v-if="props.data.footer">
          {{ props.data.footer }}
        </div>
      </div>
    </div>
  </div>
</template>
