<script setup lang="ts">
import { c2FeatureDataApi, ServerError } from '@/api';
import {
  C2FeatureDataDto,
  C2FeatureDateCount,
  DateTypeEnum,
  LastFeatureDataDto,
  DateInfo,
  DashboardSelectionTypeEnum,
} from '@/api/client';
import { useAmountFormatter } from '@/composables/useAmountFormatter';
import { useDateFormatter } from '@/composables/useDateFormatter';
import { MessageSchema } from '@/lib/i18n';
import { useDateStore } from '@/stores/date';
import { IDate } from '@/types/date.type';
import axios, { AxiosError } from 'axios';
import {
  addBusinessDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
} from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useNotificationBadge } from '@/composables/useNotificationBadge';

const props = defineProps<{
  propC2TypeOrSegmentType: string;
  propC2TypeIdOrSegmentId: string;
}>();

const start = ref(0);
const end = ref(5);
const topLeastOptions = ['All features', 'Top 5 features', 'Least 5 features'];
const selectedTopLeastOption = ref(topLeastOptions[1]);
const data = ref<C2FeatureDataDto>({ history_data: [], last_total: [] });

watch(selectedTopLeastOption, (newValue) => {
  if (newValue === topLeastOptions[0]) {
    start.value = 0;
    end.value = data.value?.history_data?.length || 0;
  } else if (newValue === topLeastOptions[1]) {
    start.value = 0;
    end.value = 5;
  } else if (newValue === topLeastOptions[2]) {
    start.value = -5;
    end.value = data.value?.history_data?.length || 0;
  }
  formatData(data.value);
});

interface IRows {
  name: string;
  currentAvg: number;
  lastAvg: number;
  movement: number;
  data: IColumn[];
}
interface IColumn {
  date: Date;
  display: string;
  isLow: number;
  count: number;
  isCurrent: boolean;
}

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});
// const { notify } = useQuasar();

const { notify } = useNotificationBadge();

const { dateInfo, iDate } = storeToRefs(useDateStore());
const { getIDateWeekObj, getIDateDayObj, getIDateMonthObj, getIDateQuarterObj, getIDateYearObj } = useDateFormatter();
const { formatNumberRoundOfDecimal, formatNumericalValue } = useAmountFormatter();

const loading = ref(false);

const rows = ref<IRows[]>([]);

const columns = computed(() => {
  const items = [] as IDate[];
  if (!dateInfo.value?.date) {
    return items;
  }
  const currentDate = new Date(dateInfo.value.date);
  switch (dateInfo.value?.type) {
    case DateTypeEnum.Day:
      for (let i = 4; i >= 0; i--) {
        const date = addBusinessDays(currentDate, -i);
        const dayObj = getIDateDayObj(date);
        items.push(dayObj);
      }
      break;
    case DateTypeEnum.Week:
      for (let i = 3; i >= 0; i--) {
        const weekDate = addWeeks(currentDate, -i);
        const weekObj = getIDateWeekObj(weekDate);
        items.push(weekObj);
      }
      break;
    case DateTypeEnum.Month:
      for (let i = 2; i >= 0; i--) {
        const date = addMonths(currentDate, -i);
        const obj = getIDateMonthObj(date);
        items.push(obj);
      }
      break;
    case DateTypeEnum.Quarter:
      for (let i = 3; i >= 0; i--) {
        const date = addQuarters(currentDate, -i);
        const obj = getIDateQuarterObj(date);
        items.push(obj);
      }
      break;
    case DateTypeEnum.Year:
      for (let i = 4; i >= 0; i--) {
        const date = addYears(currentDate, -i);
        const obj = getIDateYearObj(date);
        items.push(obj);
      }
      break;
  }

  return items;
});

const displayColumn = computed(() => {
  const items = [
    {
      label: 'FeatureName',
      name: 'name',
      field: 'name',
      align: 'left' as const,
      sortable: true,
    },
  ];
  columns.value.forEach((x) => {
    items.push({
      label: x.text,
      name: x.text,
      field: x.text,
      align: 'left' as const,
      sortable: true,
    });
  });
  if (displayCurrentAvg.value) {
    let currentLabel = '';
    let lastLabel = '';
    switch (dateInfo.value?.type) {
      case DateTypeEnum.Day:
        currentLabel = t('c2_feature_data.current_avg_day');
        lastLabel = t('c2_feature_data.last_avg_day');
        break;
      case DateTypeEnum.Week:
        currentLabel = t('c2_feature_data.current_avg_week');
        lastLabel = t('c2_feature_data.last_avg_week');
        break;
      case DateTypeEnum.Month:
        currentLabel = t('c2_feature_data.current_avg_month');
        lastLabel = t('c2_feature_data.last_avg_month');
        break;
      case DateTypeEnum.Quarter:
        currentLabel = t('c2_feature_data.current_avg_quarter');
        lastLabel = t('c2_feature_data.last_avg_quarter');
        break;
    }
    items.push({
      label: currentLabel,
      name: 'current_avg',
      field: 'current_avg',
      align: 'left' as const,
      sortable: true,
    });
    items.push({
      label: lastLabel,
      name: 'last_avg',
      field: 'last_avg',
      align: 'left' as const,
      sortable: true,
    });
  }

  items.push({
    label: t('c2_feature_data.movement'),
    name: 'movement',
    field: 'movement',
    align: 'left' as const,
    sortable: true,
  });
  return items;
});

const c2TypeId = computed(() => {
  return props.propC2TypeOrSegmentType === DashboardSelectionTypeEnum.C2typehealth
    ? props.propC2TypeIdOrSegmentId
    : undefined;
});

const segmentId = computed(() => {
  return props.propC2TypeOrSegmentType === DashboardSelectionTypeEnum.C2segmenthealth
    ? props.propC2TypeIdOrSegmentId
    : undefined;
});

watchEffect(async () => {
  if (!dateInfo.value || !dateInfo.value.type) {
    return;
  }
  await onRequest(dateInfo.value, c2TypeId.value, segmentId.value);
});

async function onRequest(dateInfo: DateInfo, c2TypeId?: string | undefined, segmentId?: string | undefined) {
  loading.value = true;
  try {
    const { data: response } = await c2FeatureDataApi.c2FeatureDataControllerFindAllProductFeatureForDashboard({
      date: dateInfo?.date,
      dateUtc: dateInfo?.dateUtc,
      type: dateInfo?.type,
      c2TypeId,
      segmentId,
    });
    if (!response) {
      data.value = { history_data: [], last_total: [] } as C2FeatureDataDto;
      return;
    }
    data.value = response;
    formatData(data.value);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      console.error('axios type error');
      const err = e as AxiosError<ServerError>;
      // notify({
      //   message: err.response?.data.message,
      //   color: 'negative',
      // });
      notify(err.response?.data.message || t('something_went_wrong'), 'negative', 30000);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

function formatData(data: C2FeatureDataDto) {
  rows.value = [];
  data.history_data.forEach((x) => {
    const row = {} as IRows;
    row.name = x.feature_name ? x.feature_name : x.name;
    row.data = [];
    const currentCount = [] as number[];
    columns.value.forEach((col) => {
      let isFound: C2FeatureDateCount | undefined = undefined;
      switch (iDate.value?.type) {
        case DateTypeEnum.Day: {
          isFound = x.data.find((item) => isSameDay(new Date(item.label), col.endDate));
          if (isSameWeek(col.endDate, iDate.value?.endDate, { weekStartsOn: 1 })) {
            currentCount.push(isFound?.count || 0);
          }
          break;
        }
        case DateTypeEnum.Week:
          isFound = x.data.find((item) => isSameWeek(new Date(item.label), col.endDate, { weekStartsOn: 1 }));
          if (isSameMonth(col.endDate, iDate.value?.endDate)) {
            currentCount.push(isFound?.count || 0);
          }
          break;
        case DateTypeEnum.Month:
          isFound = x.data.find((item) => isSameMonth(new Date(item.label), col.endDate));
          if (isSameQuarter(col.endDate, iDate.value?.endDate)) {
            currentCount.push(isFound?.count || 0);
          }
          break;
        case DateTypeEnum.Quarter:
          isFound = x.data.find((item) => isSameQuarter(new Date(item.label), col.endDate));
          if (isSameYear(col.endDate, iDate.value?.endDate)) {
            currentCount.push(isFound?.count || 0);
          }
          break;
        case DateTypeEnum.Year:
          isFound = x.data.find((item) => isSameYear(new Date(item.label), col.endDate));
          break;
      }
      row.data.push({ date: col.endDate, display: col.text, isLow: 0, count: isFound?.count || 0 } as IColumn);
    });
    const minToMax = [...new Set([...row.data].sort((x, y) => (x.count > y.count ? 1 : -1)).map((x) => x.count))];
    row.data.forEach((x) => {
      x.isLow = 0;
      if (minToMax.length >= 1 && x.count === minToMax[0]) {
        x.isLow = -2;
      }
      if (minToMax.length >= 2 && x.count === minToMax[1]) {
        x.isLow = -1;
      }
    });
    if (dateInfo.value?.type !== DateTypeEnum.Year) {
      const currentAvg = +formatNumberRoundOfDecimal(
        currentCount.reduce((a, b) => a + b || 0, 0) / currentCount.length,
      );
      row.currentAvg = currentAvg;
      const lastAvg = data.last_total.find((y) => y._id === row.name) || ({ avg: 0 } as LastFeatureDataDto);
      row.lastAvg = +formatNumberRoundOfDecimal(lastAvg.avg);
      const movement = currentAvg === 0 ? 0 : (currentAvg - row.lastAvg) * (100 / currentAvg) || 0;
      row.movement = +formatNumberRoundOfDecimal(movement);
    } else {
      const currentAvg = +formatNumberRoundOfDecimal(row.data[row.data.length - 1].count / 365 || 0);
      const lastAvg = +formatNumberRoundOfDecimal(row.data[row.data.length - 2].count / 365);
      const movement = currentAvg === 0 ? 0 : (currentAvg - lastAvg) * (100 / currentAvg) || 0;
      row.movement = +formatNumberRoundOfDecimal(movement);
    }
    rows.value.push(row);
  });

  rows.value = rows.value.sort((item1, item2) => item2.currentAvg - item1.currentAvg).slice(start.value, end.value);
}

function getCountBackgroundColor(column: IColumn) {
  let result = '';
  if (column.isLow === undefined) {
    result = '!tw-bg-light-shade-2';
  } else {
    if (column.isLow === -2) {
      result = '!tw-bg-data-viz-semantic-red-graph';
    } else if (column.isLow === -1) {
      result = '!tw-bg-data-viz-semantic-yellow-graph';
    } else {
      result = '!tw-bg-data-viz-semantic-green-graph';
    }
  }
  return result;
}

function getMovementColor(movement: number) {
  if (movement < 0) {
    return 'tw-text-data-viz-semantic-red-content';
  }
  return '';
}

const displayCurrentAvg = computed(() => {
  return dateInfo.value?.type !== DateTypeEnum.Year;
});
</script>

<template>
  <!-- <div class="tw-px-6 tw-mt-6"> -->
  <div class="tw-relative tw-rounded tw-bg-light-shade-1 tw-shadow">
    <div class="tw-relative tw-px-6">
      <div class="tw-flex tw-items-center tw-justify-between tw-pt-4">
        <span class="tw-text-base tw-font-medium tw-text-dark-shade-3">Product Feature Usage</span>
        <q-select outlined input-debounce="0" v-model="selectedTopLeastOption" :options="topLeastOptions" :dense="true">
        </q-select>
      </div>
    </div>

    <q-table
      :rows="rows"
      :row-key="(row) => row.id"
      :columns="displayColumn"
      hide-pagination
      binary-state-sort
      :rows-per-page-options="[0]"
      :loading="loading"
      separator="cell"
      class="no-shadow overflow-y-auto tw-mt-2 tw-max-h-[300px] tw-border tw-border-light-shade-4"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary" class="z-max" size="md" />
      </template>

      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            <span class="tw-text-sm tw-font-semibold tw-leading-5 tw-text-dark-shade-4">{{ col.label }}</span>
          </q-th>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="name" :props="props"> {{ props.row?.name }} </q-td>
          <q-td v-for="(info, index) in props.row.data" :key="index" :class="getCountBackgroundColor(info)">
            <div>
              {{ formatNumericalValue(info.count) }}
            </div>
          </q-td>
          <q-td key="current_avg" v-if="displayCurrentAvg">{{ props.row.currentAvg }}</q-td>
          <q-td key="last_avg" v-if="displayCurrentAvg">{{ props.row.lastAvg }}</q-td>
          <q-td key="movement" :class="getMovementColor(props.row.movement)">{{ props.row.movement }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>
