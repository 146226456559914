import { DateTypeEnum } from '@/api/client';
import { IDate } from '@/types/date.type';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getMonth,
  getYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useCustomDateUtil } from './useCustomDateUtil';
import { useAccountStore } from '@/stores/account';

export const useDateFormatter = () => {
  const monthsName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const {
    monthShortName,
    startOfCustomQuarter,
    endOfCustomQuarter,
    getCustomQuarterNumber,
    getCustomYearStart,
    getCustomYearEnd,
  } = useCustomDateUtil();

  function formatRenewalDate(renewalDate: number | undefined) {
    if (!renewalDate) return 0;
    return Math.abs(renewalDate);
  }

  function getDateString(date: Date) {
    return format(date, 'yyyy/MM/dd');
  }

  function formatRenewalDateString(renewalDate: number | undefined) {
    if (!renewalDate) return 'renewal_days';
    if (renewalDate < 0) return 'expired_days';
    else return 'renewal_days';
  }

  function getIDateDayObj(dayDate: Date) {
    const startDate = startOfDay(dayDate);
    const endDate = endOfDay(dayDate);
    const text = format(startDate, 'yyyy/MM/dd');
    return {
      type: DateTypeEnum.Day,
      isDisabled: false,
      startDate,
      endDate,
      text,
    } as IDate;
  }

  function getIDateWeekObj(weekDate: Date) {
    const weekStartDate = getStartOfWeekDate(weekDate);
    const weekEndDate = getEndOfWeekDate(weekDate);
    const weekNo = iso8601_week_no(weekDate);
    const year = getYear(weekDate);

    return {
      text: `W${weekNo} (${format(weekStartDate, 'dd-MMM')} - ${format(weekEndDate, 'dd-MMM')}) ${year}`,
      type: DateTypeEnum.Week,
      startDate: weekStartDate,
      endDate: weekEndDate,
      isDisabled: false,
      itemNo: weekNo,
    } as IDate;
  }

  function getIDateMonthObj(selectedDate: Date) {
    const monthStartDate = startOfMonth(selectedDate);
    const monthEndDate = endOfMonth(selectedDate);
    const monthNo = getMonth(selectedDate);
    const year = getYear(selectedDate);
    return {
      type: DateTypeEnum.Month,
      isDisabled: false,
      text: `${monthsName[monthNo]} - ${year}`,
      startDate: monthStartDate,
      endDate: monthEndDate,
      itemNo: monthNo,
    } as IDate;
  }

  function getIDateQuarterObj(selectedDate: Date) {
    const { quarterStartMonthIndex } = useAccountStore();
    const quarterStartDate = startOfCustomQuarter(selectedDate, quarterStartMonthIndex || 0);
    const quarterEndDate = endOfCustomQuarter(selectedDate, quarterStartMonthIndex || 0);

    const startMonthIndex = getMonth(quarterStartDate);
    const endMonthIndex = getMonth(quarterEndDate);

    const quarterNo = getCustomQuarterNumber(selectedDate, quarterStartMonthIndex);

    return {
      type: DateTypeEnum.Quarter,
      isDisabled: false,
      text: `Q${quarterNo} (${monthShortName[startMonthIndex]} - ${monthShortName[endMonthIndex]}) - ${getYear(
        quarterEndDate,
      )}`,
      startDate: quarterStartDate,
      endDate: quarterEndDate,
      itemNo: quarterNo,
    } as IDate;
  }

  function getIDateYearObj(selectedDate: Date) {
    const { yearStartMonthIndex } = useAccountStore();
    const selectedYear = getYear(selectedDate);
    let startDate = new Date();
    if (yearStartMonthIndex === 0) {
      startDate = startOfYear(selectedDate);
    } else {
      startDate = getCustomYearStart(selectedYear, yearStartMonthIndex + 1);
    }
    let endDate = new Date();
    if (yearStartMonthIndex === 0) {
      endDate = endOfYear(selectedDate);
    } else {
      endDate = getCustomYearEnd(selectedYear, yearStartMonthIndex + 1);
    }
    const startDateText = format(startDate, 'MMM yyyy');
    const endDateText = format(endDate, 'MMM yyyy');
    const year = getYear(selectedDate);
    return {
      type: DateTypeEnum.Year,
      isDisabled: false,
      text: `${startDateText} - ${endDateText}`,
      startDate,
      endDate,
      itemNo: year,
    } as IDate;
  }

  function getStartOfWeekDate(date: Date) {
    return startOfWeek(date, { weekStartsOn: 1 });
  }

  function getEndOfWeekDate(date: Date) {
    return endOfWeek(date, { weekStartsOn: 1 });
  }

  function iso8601_week_no(dt: Date) {
    const tdt = new Date(dt);
    const dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt.getTime()) / 604800000);
  }

  function getWeekNo(dt: Date) {
    return iso8601_week_no(dt);
  }

  function getDateWithTime(emailDateStr: string) {
    return format(new Date(emailDateStr), 'dd MMM yyyy, hh:mm aaa');
  }

  return {
    getDateString,
    formatRenewalDate,
    formatRenewalDateString,
    getIDateDayObj,
    getIDateWeekObj,
    getIDateMonthObj,
    getIDateQuarterObj,
    getIDateYearObj,
    iso8601_week_no,
    getWeekNo,
    getDateWithTime,
  };
};
