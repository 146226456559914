import { Notify } from 'quasar';
import { phX } from '@/phosphor-icons';

export const useNotificationBadge = () => {
  function notify(text: string, sentiment: string, duration: number = 30000) {
    Notify.create({
      message: text,
      color: sentiment,
      type: sentiment,
      timeout: duration,
      actions: [
        {
          icon: phX,
          color: 'white',
          round: true,
          size: '10px',
        },
      ],
    });
  }
  return { notify };
};
