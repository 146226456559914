<script setup lang="ts">
import { phList, phSignOut, phTerminalWindow, phUserCircleGear } from '@/phosphor-icons';
import { useAccountStore } from '@/stores/account';
import { useConfigStore } from '@/stores/config';
import { RouteName } from '@/router/route-name';
import Logo from './Logo.vue';
import { useAuth } from '@/composables/useAuth';
import { useI18n } from 'vue-i18n';
import { MessageSchema } from '@/lib/i18n';
// import PlaybookModal from '@/components/Playbook/PlaybookModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuasar } from 'quasar';
import DeveloperSettingsTrayModal from './DeveloperSettingsTrayModal.vue';
import AppNotifications from './AppNotifications.vue';
import GlobalSearchForm from './GlobalSearchForm.vue';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

const $q = useQuasar();

const roleBasedFF = import.meta.env.VITE_FF_ROLE_BASED_ACCESS;

const { isAuthenticated, logout } = useAuth();
const accountStore = useAccountStore();
const { activeC1Master } = storeToRefs(accountStore);

const configStore = useConfigStore();

const route = useRoute();
const router = useRouter();

const openDeveloperSettingsModal = () => {
  $q.dialog({
    component: DeveloperSettingsTrayModal,
  })
    .onOk(() => {
      console.log('developer settings dialog close with ok');
    })
    .onCancel(() => {
      console.log('developer settings dialog close with cancel');
    });
};

const gotoManageSection = () => {
  route.meta.group = RouteName.Manage;
  router.push({ name: RouteName.Manage });
};

const showGlobalSearch = computed(() => {
  return activeC1Master.value;
});
</script>

<template>
  <q-header class="tw-bg-light-shade-1 tw-text-primary-dark">
    <q-toolbar class="tw-flex tw-h-14 tw-flex-row tw-items-center tw-justify-between tw-border-b tw-px-4">
      <div class="tw-flex tw-items-center">
        <q-btn
          :ripple="false"
          dense
          flat
          round
          :icon="phList"
          class="tw-mr-4 lg:tw-hidden"
          @click="configStore.toggleAppDrawer"
        />

        <router-link :to="{ name: RouteName.Dashboard }">
          <Logo />
        </router-link>
      </div>

      <template v-if="isAuthenticated">
        <!-- <q-icon
          :name="phQuestion"
          :size="'1rem'"
          color="primary"
          class="tw-mr-6 tw-cursor-pointer"
          @click="openPlayBook"
        /> -->
        <div class="tw-flex tw-items-center tw-gap-4">
          <GlobalSearchForm v-if="showGlobalSearch" />

          <AppNotifications v-if="accountStore.activeC1Master" />
          <button class="tw-group tw-flex tw-flex-row tw-items-center tw-p-1" v-if="accountStore.account">
            <q-avatar
              class="tw-ml-2 tw-h-8 tw-w-8 tw-select-none tw-bg-primary-dark tw-uppercase tw-text-light-shade-1 group-hover:tw-opacity-90"
            >
              <img :src="accountStore.imageUrl" alt="Profile Photo" v-if="accountStore.imageUrl" />
              <span v-else>
                {{ accountStore.account.name[0] }}
              </span>
            </q-avatar>
            <q-menu class="tw-border tw-bg-light-shade-1 tw-shadow">
              <q-list class="tw-text-sm tw-font-medium tw-text-dark-shade-1">
                <q-item-label header>
                  <div class="tw-flex tw-cursor-default tw-flex-col tw-gap-1">
                    <div>
                      {{ accountStore.account.name }}
                    </div>
                    <div>
                      {{ accountStore.account.email }}
                    </div>
                  </div>
                </q-item-label>
                <q-separator />

                <q-item @click="gotoManageSection" clickable v-close-popup v-if="roleBasedFF === '1'">
                  <q-item-section class="tw-min-w-0" avatar>
                    <q-icon :name="phUserCircleGear" class="tw-h-5 tw-w-5" />
                  </q-item-section>
                  <q-item-section>{{ t('app_header.manage_account') }}</q-item-section>
                </q-item>

                <q-item @click="openDeveloperSettingsModal" clickable v-close-popup v-else>
                  <q-item-section class="tw-min-w-0" avatar>
                    <q-icon :name="phTerminalWindow" class="tw-h-5 tw-w-5" />
                  </q-item-section>
                  <q-item-section>{{ t('app_header.developer_settings') }}</q-item-section>
                </q-item>

                <q-separator />
                <q-item @click="logout" clickable v-close-popup>
                  <q-item-section class="tw-min-w-0" avatar>
                    <q-icon :name="phSignOut" class="tw-h-5 tw-w-5" />
                  </q-item-section>
                  <q-item-section>{{ t('app_header.logout') }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </button>
        </div>
      </template>
    </q-toolbar>

    <slot name="subheader" />
  </q-header>
</template>

<style scoped>
.q-header :deep(.q-tab__content) {
  min-width: auto;
}
</style>
