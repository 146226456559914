import { C2InlineEditSystemFieldDto, CreateAppCustomFieldLogDto } from '@/api/client';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useCustomerListStore = defineStore('customer-list', () => {
  const isListLoading = ref<boolean>(false);
  const editMode = ref<boolean>(false);

  // const isEditCanceled = ref<boolean>(false);

  const customFieldValueQueue = ref<CreateAppCustomFieldLogDto[]>([]);
  const systemFieldValueQueue = ref<C2InlineEditSystemFieldDto[]>([]);

  const customFieldQueueLength = computed(() => {
    return customFieldValueQueue.value.length;
  });

  const systemFieldQueueLength = computed(() => {
    return systemFieldValueQueue.value.length;
  });

  const showSaveButton = computed(() => {
    const data = (customFieldQueueLength.value > 0 || systemFieldQueueLength.value > 0) && editMode.value;
    return data;
  });

  const addCustomFieldValues = (value: CreateAppCustomFieldLogDto) => {
    const uniqueValues = customFieldValueQueue.value.filter(
      (x) =>
        x.custom_field_name !== value.custom_field_name ||
        x.app_c2_id !== value.app_c2_id ||
        x.source !== value.source ||
        x.custom_field_value_type !== value.custom_field_value_type,
    );
    uniqueValues.push(value);
    customFieldValueQueue.value = [...uniqueValues];
  };

  const addSystemFieldValues = (value: C2InlineEditSystemFieldDto) => {
    const uniqueValues = systemFieldValueQueue.value.filter(
      (x) =>
        x.c2_health_score_id !== value.c2_health_score_id ||
        x.c2_id !== value.c2_id ||
        x.c2_app_id !== value.c2_app_id ||
        x.system_field_name !== value.system_field_name,
    );
    uniqueValues.push(value);
    systemFieldValueQueue.value = [...uniqueValues];
  };

  const popCustomFieldValueQueue = (value: CreateAppCustomFieldLogDto) => {
    customFieldValueQueue.value = customFieldValueQueue.value.filter(
      (x) =>
        x.custom_field_name !== value.custom_field_name || x.app_c2_id !== value.app_c2_id || x.source !== value.source,
    );
  };

  const popSystemFieldValueQueue = (value: C2InlineEditSystemFieldDto) => {
    systemFieldValueQueue.value = systemFieldValueQueue.value.filter(
      (x) =>
        x.c2_health_score_id !== value.c2_health_score_id ||
        x.c2_id !== value.c2_id ||
        x.c2_app_id !== value.c2_app_id ||
        x.system_field_name !== value.system_field_name,
    );
  };

  const resetCustomFieldValueQueue = () => {
    customFieldValueQueue.value = [];
  };

  const resetSystemFieldValueQueue = () => {
    systemFieldValueQueue.value = [];
  };

  const onResetClearTableCell = ref<() => void>();

  return {
    isListLoading,
    // isEditCanceled,
    editMode,
    addCustomFieldValues,
    popCustomFieldValueQueue,
    customFieldQueueLength,
    showSaveButton,
    resetCustomFieldValueQueue,
    onResetClearTableCell,
    customFieldValueQueue,
    addSystemFieldValues,
    popSystemFieldValueQueue,
    resetSystemFieldValueQueue,
    systemFieldValueQueue,
    systemFieldQueueLength,
  };
});
