/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateRenewalDealLogDto } from '../model/';
// @ts-ignore
import { CrmRenewalLogs } from '../model/';
// @ts-ignore
import { DealOwnerDto } from '../model/';
// @ts-ignore
import { DealsFilterDto } from '../model/';
// @ts-ignore
import { ForecastAggregationFilterDto } from '../model/';
// @ts-ignore
import { LatestDealDto } from '../model/';
// @ts-ignore
import { RoleBasedDealsAggregationDto } from '../model/';
// @ts-ignore
import { RoleBasedRenewalDealsPaginationDto } from '../model/';
// @ts-ignore
import { RoleBasedRenewalForecastDto } from '../model/';
/**
 * RenewalDealsApi - axios parameter creator
 * @export
 */
export const RenewalDealsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerAllDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerAllDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/all-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerAllDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerAllDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/all-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Peripheral renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerCreatePeripheralRenewalLog: async (createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRenewalDealLogDto' is not null or undefined
            assertParamExists('renewalDealsControllerCreatePeripheralRenewalLog', 'createRenewalDealLogDto', createRenewalDealLogDto)
            const localVarPath = `/api/renewal-deals/create-peripheral-renewal-deal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRenewalDealLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerCreateRenewalLog: async (createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRenewalDealLogDto' is not null or undefined
            assertParamExists('renewalDealsControllerCreateRenewalLog', 'createRenewalDealLogDto', createRenewalDealLogDto)
            const localVarPath = `/api/renewal-deals/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRenewalDealLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealHistoryLogs: async (id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renewalDealsControllerDealHistoryLogs', 'id', id)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('renewalDealsControllerDealHistoryLogs', 'source', source)
            const localVarPath = `/api/renewal-deals/{id}/deal-history-logs/{source}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealOwners: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('renewalDealsControllerDealOwners', 'source', source)
            const localVarPath = `/api/renewal-deals/{source}/deal-owners`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealPipelines: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('renewalDealsControllerDealPipelines', 'source', source)
            const localVarPath = `/api/renewal-deals/{source}/pipeline-names`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealStatuses: async (source: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('renewalDealsControllerDealStatuses', 'source', source)
            const localVarPath = `/api/renewal-deals/{source}/deal-status`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest Renewal Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerFindLatest: async (dealId: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealId' is not null or undefined
            assertParamExists('renewalDealsControllerFindLatest', 'dealId', dealId)
            const localVarPath = `/api/renewal-deals/{deal_id}`
                .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forecast Aggregation for Deal
         * @param {ForecastAggregationFilterDto} forecastAggregationFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerForecastAggregation: async (forecastAggregationFilterDto: ForecastAggregationFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forecastAggregationFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerForecastAggregation', 'forecastAggregationFilterDto', forecastAggregationFilterDto)
            const localVarPath = `/api/renewal-deals/forecast-aggregation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forecastAggregationFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedAllDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedAllDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-all-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedAllDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedAllDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-all-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedPeripheralDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedPeripheralDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-peripheral-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedPeripheralDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedPeripheralDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-peripheral-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedZsDeals: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedZsDeals', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-zs-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedZsDealsForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPaginatedZsDealsForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/paginated-zs-deals-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2: async (id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'id', id)
            // verify required parameter 'source' is not null or undefined
            assertParamExists('renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'source', source)
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/{id}/deal-history-logs-c2/{source}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPeripheralDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/peripheral-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerPeripheralDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/peripheral-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerZsDealsAggregationCount: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerZsDealsAggregationCount', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/zs-deals-aggregation-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerZsDealsAggregationCountForC2: async (dealsFilterDto: DealsFilterDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealsFilterDto' is not null or undefined
            assertParamExists('renewalDealsControllerZsDealsAggregationCountForC2', 'dealsFilterDto', dealsFilterDto)
            const localVarPath = `/api/renewal-deals/zs-deals-aggregation-count-c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dealsFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RenewalDealsApi - functional programming interface
 * @export
 */
export const RenewalDealsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RenewalDealsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerAllDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerAllDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerAllDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerAllDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Peripheral renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerCreatePeripheralRenewalLog(createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmRenewalLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerCreatePeripheralRenewalLog(createRenewalDealLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerCreateRenewalLog(createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CrmRenewalLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerCreateRenewalLog(createRenewalDealLogDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerDealHistoryLogs(id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerDealHistoryLogs(id, source, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerDealOwners(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DealOwnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerDealOwners(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerDealPipelines(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerDealPipelines(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerDealStatuses(source: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerDealStatuses(source, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Latest Renewal Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerFindLatest(dealId: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestDealDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerFindLatest(dealId, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Forecast Aggregation for Deal
         * @param {ForecastAggregationFilterDto} forecastAggregationFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerForecastAggregation(forecastAggregationFilterDto: ForecastAggregationFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalForecastDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerForecastAggregation(forecastAggregationFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedAllDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedAllDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedAllDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedAllDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedPeripheralDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedPeripheralDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedZsDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedZsDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPaginatedZsDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPaginatedZsDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedRenewalDealsPaginationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id, source, dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPeripheralDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPeripheralDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerZsDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerZsDealsAggregationCount(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renewalDealsControllerZsDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBasedDealsAggregationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renewalDealsControllerZsDealsAggregationCountForC2(dealsFilterDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RenewalDealsApi - factory interface
 * @export
 */
export const RenewalDealsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RenewalDealsApiFp(configuration)
    return {
        /**
         * 
         * @summary All Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerAllDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerAllDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary All Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerAllDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerAllDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Peripheral renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerCreatePeripheralRenewalLog(createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options?: any): AxiosPromise<CrmRenewalLogs> {
            return localVarFp.renewalDealsControllerCreatePeripheralRenewalLog(createRenewalDealLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Renewal Deal Log
         * @param {CreateRenewalDealLogDto} createRenewalDealLogDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerCreateRenewalLog(createRenewalDealLogDto: CreateRenewalDealLogDto, xTenantId?: string, options?: any): AxiosPromise<CrmRenewalLogs> {
            return localVarFp.renewalDealsControllerCreateRenewalLog(createRenewalDealLogDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deal History Logs
         * @param {string} id 
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealHistoryLogs(id: string, source: string, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerDealHistoryLogs(id, source, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all unique deal owners
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealOwners(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<DealOwnerDto>> {
            return localVarFp.renewalDealsControllerDealOwners(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Unique Deal Pipelines
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealPipelines(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.renewalDealsControllerDealPipelines(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Unique Deal Statuses
         * @param {string} source 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerDealStatuses(source: string, xTenantId?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.renewalDealsControllerDealStatuses(source, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Latest Renewal Deal Log by Deal ID
         * @param {string} dealId 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerFindLatest(dealId: string, xTenantId?: string, options?: any): AxiosPromise<LatestDealDto> {
            return localVarFp.renewalDealsControllerFindLatest(dealId, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Forecast Aggregation for Deal
         * @param {ForecastAggregationFilterDto} forecastAggregationFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerForecastAggregation(forecastAggregationFilterDto: ForecastAggregationFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedRenewalForecastDto> {
            return localVarFp.renewalDealsControllerForecastAggregation(forecastAggregationFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated All Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedAllDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedAllDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated All Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedAllDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedAllDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Peripheral Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedPeripheralDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedPeripheralDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated Peripheral Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedPeripheralDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated ZapScale Deals
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedZsDeals(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedZsDeals(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Paginated ZapScale Deals C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPaginatedZsDealsForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPaginatedZsDealsForC2(dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deal History Logs For C2
         * @param {string} id 
         * @param {string} source 
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id: string, source: string, dealsFilterDto: DealsFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBasedRenewalDealsPaginationDto> {
            return localVarFp.renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(id, source, dealsFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerPeripheralDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Peripheral Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerPeripheralDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerZsDealsAggregationCount(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerZsDealsAggregationCount(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ZapScale Deals Aggregation Count C2
         * @param {DealsFilterDto} dealsFilterDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renewalDealsControllerZsDealsAggregationCountForC2(dealsFilterDto: DealsFilterDto, xTenantId?: string, options?: any): AxiosPromise<RoleBasedDealsAggregationDto> {
            return localVarFp.renewalDealsControllerZsDealsAggregationCountForC2(dealsFilterDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for renewalDealsControllerAllDealsAggregationCount operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountRequest
 */
export interface RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerAllDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerAllDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerAllDealsAggregationCountForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerCreatePeripheralRenewalLog operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLogRequest
 */
export interface RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLogRequest {
    /**
     * 
     * @type {CreateRenewalDealLogDto}
     * @memberof RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLog
     */
    readonly createRenewalDealLogDto: CreateRenewalDealLogDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLog
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerCreateRenewalLog operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerCreateRenewalLogRequest
 */
export interface RenewalDealsApiRenewalDealsControllerCreateRenewalLogRequest {
    /**
     * 
     * @type {CreateRenewalDealLogDto}
     * @memberof RenewalDealsApiRenewalDealsControllerCreateRenewalLog
     */
    readonly createRenewalDealLogDto: CreateRenewalDealLogDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerCreateRenewalLog
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerDealHistoryLogs operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerDealHistoryLogsRequest
 */
export interface RenewalDealsApiRenewalDealsControllerDealHistoryLogsRequest {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealHistoryLogs
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerDealOwners operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerDealOwnersRequest
 */
export interface RenewalDealsApiRenewalDealsControllerDealOwnersRequest {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealOwners
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealOwners
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerDealPipelines operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerDealPipelinesRequest
 */
export interface RenewalDealsApiRenewalDealsControllerDealPipelinesRequest {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealPipelines
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealPipelines
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerDealStatuses operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerDealStatusesRequest
 */
export interface RenewalDealsApiRenewalDealsControllerDealStatusesRequest {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealStatuses
     */
    readonly source: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerDealStatuses
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerFindLatest operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerFindLatestRequest
 */
export interface RenewalDealsApiRenewalDealsControllerFindLatestRequest {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerFindLatest
     */
    readonly dealId: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerFindLatest
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerForecastAggregation operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerForecastAggregationRequest
 */
export interface RenewalDealsApiRenewalDealsControllerForecastAggregationRequest {
    /**
     * 
     * @type {ForecastAggregationFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerForecastAggregation
     */
    readonly forecastAggregationFilterDto: ForecastAggregationFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerForecastAggregation
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedAllDeals operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedAllDealsRequest
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedAllDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDeals
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedAllDealsForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedPeripheralDeals operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsRequest
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDeals
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedPeripheralDealsForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedZsDeals operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedZsDealsRequest
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedZsDealsRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDeals
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPaginatedZsDealsForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request {
    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly source: string

    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2
     */
    readonly after?: string
}

/**
 * Request parameters for renewalDealsControllerPeripheralDealsAggregationCount operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountRequest
 */
export interface RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerPeripheralDealsAggregationCountForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerZsDealsAggregationCount operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountRequest
 */
export interface RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountRequest {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerZsDealsAggregationCount
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerZsDealsAggregationCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for renewalDealsControllerZsDealsAggregationCountForC2 operation in RenewalDealsApi.
 * @export
 * @interface RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2Request
 */
export interface RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2Request {
    /**
     * 
     * @type {DealsFilterDto}
     * @memberof RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2
     */
    readonly dealsFilterDto: DealsFilterDto

    /**
     * 
     * @type {string}
     * @memberof RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2
     */
    readonly xTenantId?: string
}

/**
 * RenewalDealsApi - object-oriented interface
 * @export
 * @class RenewalDealsApi
 * @extends {BaseAPI}
 */
export class RenewalDealsApi extends BaseAPI {
    /**
     * 
     * @summary All Deals Aggregation Count
     * @param {RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerAllDealsAggregationCount(requestParameters: RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerAllDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary All Deals Aggregation Count C2
     * @param {RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerAllDealsAggregationCountForC2(requestParameters: RenewalDealsApiRenewalDealsControllerAllDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerAllDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Peripheral renewal Deal Log
     * @param {RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerCreatePeripheralRenewalLog(requestParameters: RenewalDealsApiRenewalDealsControllerCreatePeripheralRenewalLogRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerCreatePeripheralRenewalLog(requestParameters.createRenewalDealLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Renewal Deal Log
     * @param {RenewalDealsApiRenewalDealsControllerCreateRenewalLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerCreateRenewalLog(requestParameters: RenewalDealsApiRenewalDealsControllerCreateRenewalLogRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerCreateRenewalLog(requestParameters.createRenewalDealLogDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deal History Logs
     * @param {RenewalDealsApiRenewalDealsControllerDealHistoryLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerDealHistoryLogs(requestParameters: RenewalDealsApiRenewalDealsControllerDealHistoryLogsRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerDealHistoryLogs(requestParameters.id, requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all unique deal owners
     * @param {RenewalDealsApiRenewalDealsControllerDealOwnersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerDealOwners(requestParameters: RenewalDealsApiRenewalDealsControllerDealOwnersRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerDealOwners(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Unique Deal Pipelines
     * @param {RenewalDealsApiRenewalDealsControllerDealPipelinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerDealPipelines(requestParameters: RenewalDealsApiRenewalDealsControllerDealPipelinesRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerDealPipelines(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Unique Deal Statuses
     * @param {RenewalDealsApiRenewalDealsControllerDealStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerDealStatuses(requestParameters: RenewalDealsApiRenewalDealsControllerDealStatusesRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerDealStatuses(requestParameters.source, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Latest Renewal Deal Log by Deal ID
     * @param {RenewalDealsApiRenewalDealsControllerFindLatestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerFindLatest(requestParameters: RenewalDealsApiRenewalDealsControllerFindLatestRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerFindLatest(requestParameters.dealId, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Forecast Aggregation for Deal
     * @param {RenewalDealsApiRenewalDealsControllerForecastAggregationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerForecastAggregation(requestParameters: RenewalDealsApiRenewalDealsControllerForecastAggregationRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerForecastAggregation(requestParameters.forecastAggregationFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated All Deals
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedAllDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedAllDeals(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedAllDealsRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedAllDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated All Deals C2
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedAllDealsForC2(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedAllDealsForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedAllDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Peripheral Deals
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedPeripheralDeals(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedPeripheralDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated Peripheral Deals C2
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedPeripheralDealsForC2(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedPeripheralDealsForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedPeripheralDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated ZapScale Deals
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedZsDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedZsDeals(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedZsDealsRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedZsDeals(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Paginated ZapScale Deals C2
     * @param {RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPaginatedZsDealsForC2(requestParameters: RenewalDealsApiRenewalDealsControllerPaginatedZsDealsForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPaginatedZsDealsForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deal History Logs For C2
     * @param {RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(requestParameters: RenewalDealsApiRenewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPeripheralDealHistoryLogsDateWiseForC2(requestParameters.id, requestParameters.source, requestParameters.dealsFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral Deals Aggregation Count
     * @param {RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPeripheralDealsAggregationCount(requestParameters: RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPeripheralDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Peripheral Deals Aggregation Count C2
     * @param {RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerPeripheralDealsAggregationCountForC2(requestParameters: RenewalDealsApiRenewalDealsControllerPeripheralDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerPeripheralDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ZapScale Deals Aggregation Count
     * @param {RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerZsDealsAggregationCount(requestParameters: RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountRequest, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerZsDealsAggregationCount(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ZapScale Deals Aggregation Count C2
     * @param {RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalDealsApi
     */
    public renewalDealsControllerZsDealsAggregationCountForC2(requestParameters: RenewalDealsApiRenewalDealsControllerZsDealsAggregationCountForC2Request, options?: AxiosRequestConfig) {
        return RenewalDealsApiFp(this.configuration).renewalDealsControllerZsDealsAggregationCountForC2(requestParameters.dealsFilterDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
