/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedActionUpdateLogDto } from '../model/';
// @ts-ignore
import { TaskUpdateLogFilterDto } from '../model/';
/**
 * ActionUpdateLogApi - axios parameter creator
 * @export
 */
export const ActionUpdateLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get update log of a action
         * @param {string} actionId 
         * @param {TaskUpdateLogFilterDto} taskUpdateLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionUpdateLogControllerFindUpdateLogOfAnAction: async (actionId: string, taskUpdateLogFilterDto: TaskUpdateLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('actionUpdateLogControllerFindUpdateLogOfAnAction', 'actionId', actionId)
            // verify required parameter 'taskUpdateLogFilterDto' is not null or undefined
            assertParamExists('actionUpdateLogControllerFindUpdateLogOfAnAction', 'taskUpdateLogFilterDto', taskUpdateLogFilterDto)
            const localVarPath = `/api/action-update-log/{actionId}`
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskUpdateLogFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionUpdateLogApi - functional programming interface
 * @export
 */
export const ActionUpdateLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionUpdateLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get update log of a action
         * @param {string} actionId 
         * @param {TaskUpdateLogFilterDto} taskUpdateLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionUpdateLogControllerFindUpdateLogOfAnAction(actionId: string, taskUpdateLogFilterDto: TaskUpdateLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedActionUpdateLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionUpdateLogControllerFindUpdateLogOfAnAction(actionId, taskUpdateLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionUpdateLogApi - factory interface
 * @export
 */
export const ActionUpdateLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionUpdateLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get update log of a action
         * @param {string} actionId 
         * @param {TaskUpdateLogFilterDto} taskUpdateLogFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionUpdateLogControllerFindUpdateLogOfAnAction(actionId: string, taskUpdateLogFilterDto: TaskUpdateLogFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedActionUpdateLogDto> {
            return localVarFp.actionUpdateLogControllerFindUpdateLogOfAnAction(actionId, taskUpdateLogFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionUpdateLogControllerFindUpdateLogOfAnAction operation in ActionUpdateLogApi.
 * @export
 * @interface ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnActionRequest
 */
export interface ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly actionId: string

    /**
     * 
     * @type {TaskUpdateLogFilterDto}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly taskUpdateLogFilterDto: TaskUpdateLogFilterDto

    /**
     * 
     * @type {string}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnAction
     */
    readonly after?: string
}

/**
 * ActionUpdateLogApi - object-oriented interface
 * @export
 * @class ActionUpdateLogApi
 * @extends {BaseAPI}
 */
export class ActionUpdateLogApi extends BaseAPI {
    /**
     * 
     * @summary Get update log of a action
     * @param {ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnActionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionUpdateLogApi
     */
    public actionUpdateLogControllerFindUpdateLogOfAnAction(requestParameters: ActionUpdateLogApiActionUpdateLogControllerFindUpdateLogOfAnActionRequest, options?: AxiosRequestConfig) {
        return ActionUpdateLogApiFp(this.configuration).actionUpdateLogControllerFindUpdateLogOfAnAction(requestParameters.actionId, requestParameters.taskUpdateLogFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }
}
