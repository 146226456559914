<script setup lang="ts">
import { phCalendarBold, phCaretDown, phCaretLeft, phCaretRight } from '@/phosphor-icons';
import { ComponentPublicInstance, computed, onMounted, ref, watch } from 'vue';
import { QMenu } from 'quasar';
import { IDate } from '@/types/date.type';
import { DateTypeEnum } from '@/api/client';
import YearDate from '@/components/Date/YearDate.vue';
import QuarterDate from '@/components/Date/QuarterDate.vue';
import MonthDate from '@/components/Date/MonthDate.vue';
import WeekDate from '@/components/Date/WeekDate.vue';
import DayDate from '@/components/Date/DayDate.vue';
import { useDateStore } from '@/stores/date';
import { MessageSchema } from '@/lib/i18n';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '@/stores/account';
import { useNotificationBadge } from '@/composables/useNotificationBadge';
import { useCustomerListStore } from '@/stores/customerList';

const props = defineProps<{ selection?: IDate }>();

const { t } = useI18n<{ message: MessageSchema }>({
  useScope: 'global',
});

// const $q = useQuasar();

const { onboardingCompletedAt } = storeToRefs(useAccountStore());

const dateStore = useDateStore();

const { editMode: isCustomerListEditEnabled } = storeToRefs(useCustomerListStore());

const startYear = computed(() => {
  return onboardingCompletedAt.value?.getFullYear();
});

const dateContextMenu = ref<ComponentPublicInstance<QMenu>>();
const selectedIDate = ref<IDate>({} as IDate);
const appliedIDate = ref<IDate>();
const selectedYear = ref<number>(0);
// const startYear = ref<number>(0);
const currentDate = ref<Date>(new Date());
const list = [DateTypeEnum.Year, DateTypeEnum.Quarter, DateTypeEnum.Month, DateTypeEnum.Week, DateTypeEnum.Day];
const selectionType = ref<DateTypeEnum>();

const emits = defineEmits(['onDateFilterApplied']);

const years: number[] = [];

const { notify } = useNotificationBadge();

function onMenuClose() {
  selectionType.value = props.selection?.type || DateTypeEnum.Day;
}

watch(
  () => props.selection,
  () => {
    if (!props.selection) {
      return;
    }
    selectedIDate.value = {
      ...props.selection,
    };
    selectionType.value = props.selection.type;
  },
  { immediate: true },
);

onMounted(() => {
  currentDate.value = new Date();
  selectedYear.value = currentDate.value.getFullYear();
  // startYear.value =
  // onboardingCompletedAt.value != null ? onboardingCompletedAt.value.getFullYear() : selectedYear.value - 19;
  for (let i = selectedYear.value; i >= startYear.value; i--) {
    years.push(i);
  }
});

function onDateSelection(iDate: IDate) {
  appliedIDate.value = {
    ...iDate,
  };
}

function onLeftButtonClick() {
  if (isCustomerListEditEnabled.value) {
    return;
  }
  const previousDate = dateStore.previousDate(dateStore.iDate);
  if (!previousDate) {
    // $q.notify({
    //   message: t('previous_date_error'),
    //   color: 'negative',
    // });
    notify(t('previous_date_error'), 'negative', 30000);
    return;
  }
  appliedIDate.value = previousDate;
  emits('onDateFilterApplied', appliedIDate.value);
}

function onRightButtonClick() {
  if (isCustomerListEditEnabled.value) {
    return;
  }
  const nextDate = dateStore.nextDate(dateStore.iDate);
  if (!nextDate) {
    // $q.notify({
    //   message: t('next_date_error'),
    //   color: 'negative',
    // });
    notify(t('next_date_error'), 'negative', 30000);
    return;
  }
  appliedIDate.value = nextDate;
  emits('onDateFilterApplied', appliedIDate.value);
}

function onFilterApplied() {
  dateContextMenu.value?.toggle();
  if (!appliedIDate.value) {
    return;
  }
  emits('onDateFilterApplied', appliedIDate.value);
}
</script>
<template>
  <div class="tw-flex tw-flex-row tw-items-center">
    <span class="tw-pr-2 tw-text-sm tw-font-semibold tw-text-dark-shade-3">{{ t('date_selection') }}</span>
    <div
      @click="onLeftButtonClick"
      class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded tw-border tw-border-light-shade-4"
      :class="
        !isCustomerListEditEnabled ? 'hover:tw-cursor-pointer hover:tw-border-dark-shade-4' : 'tw-cursor-not-allowed'
      "
    >
      <q-icon :name="phCaretLeft" size="0.85rem" />
    </div>
    <q-input
      :disable="isCustomerListEditEnabled"
      outlined
      v-model="dateStore.dateText"
      dense
      class="custom-icon tw-mx-1 tw-w-[17rem] tw-cursor-pointer"
    >
      <template v-slot:prepend>
        <q-icon :name="phCalendarBold" class="tw-text-base" />
      </template>
      <template v-slot:append>
        <q-icon :name="phCaretDown" class="tw-text-xs" />
      </template>
      <q-menu v-if="!isCustomerListEditEnabled" ref="dateContextMenu" class="tw-overflow-x-hidden" @hide="onMenuClose">
        <div class="tw-flex tw-w-max tw-flex-col">
          <div class="tw-flex tw-flex-grow tw-flex-row">
            <div class="tw-bg-light-shade-1 tw-pl-5 tw-pr-6">
              <q-list>
                <q-item v-for="item in list" :key="item" dense>
                  <q-item-section>
                    <q-radio
                      v-model="selectionType"
                      :val="item"
                      :label="item"
                      class="tw-text-sm tw-font-semibold tw-text-dark-shade-1"
                    ></q-radio>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="tw-flex tw-w-80 tw-flex-col tw-border-l tw-border-grey-shade-1 tw-bg-light-shade-1">
              <div v-if="selectionType !== DateTypeEnum.Day" class="tw-flex tw-flex-row tw-pl-3 tw-pr-4">
                <div class="tw-grow tw-self-center tw-py-4">Select Year</div>
                <q-select
                  dense
                  borderless
                  v-show="selectionType !== DateTypeEnum.Year"
                  v-model="selectedYear"
                  :options="years"
                  class="tw-self-center"
                />
              </div>
              <div class="tw-ml-px tw-flex-grow tw-bg-light-shade-2">
                <YearDate
                  v-if="selectionType === DateTypeEnum.Year"
                  :years="years"
                  :current-year="selectedYear"
                  :selected-date="selectedIDate"
                  @on-date-selection="onDateSelection"
                />
                <QuarterDate
                  v-else-if="selectionType === DateTypeEnum.Quarter"
                  :selected-year="selectedYear"
                  :selected-date="selectedIDate"
                  @on-date-selection="onDateSelection"
                />
                <MonthDate
                  v-else-if="selectionType === DateTypeEnum.Month"
                  :selected-year="selectedYear"
                  :selected-date="selectedIDate"
                  @on-date-selection="onDateSelection"
                />
                <WeekDate
                  v-else-if="selectionType === DateTypeEnum.Week"
                  :year="selectedYear"
                  :selected-date="selectedIDate"
                  @on-date-selection="onDateSelection"
                />
                <DayDate v-else :selected-date="selectedIDate" @on-date-selection="onDateSelection" />
              </div>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-row-reverse tw-items-stretch tw-justify-items-stretch tw-bg-light-shade-2 tw-px-4 tw-py-2.5"
          >
            <q-btn
              color="primary"
              text-color="white"
              no-caps
              label="Apply Filter"
              :ripple="false"
              :size="'1rem'"
              class="q-px-sm q-py-xs tw-font-bold"
              @click="onFilterApplied"
            />
          </div>
        </div>
      </q-menu>
    </q-input>

    <div
      @click="onRightButtonClick"
      class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded tw-border tw-border-light-shade-4"
      :class="
        isCustomerListEditEnabled
          ? 'hover:tw-cursor-not-allowed'
          : 'hover:tw-cursor-pointer hover:tw-border-dark-shade-4'
      "
    >
      <q-icon :name="phCaretRight" size="0.85rem" />
    </div>
  </div>
</template>
<style scoped lang="postcss">
.custom-icon :deep(.q-field__prepend) {
  @apply tw--ml-3 tw-mr-1.5 tw-justify-center tw-rounded-r tw-border-r tw-border-grey-shade-1 tw-px-3 tw-py-2.5;
}
</style>
