<script setup lang="ts">
import { QBtn, QCheckbox, QDialog, useDialogPluginComponent } from 'quasar';
import { onBeforeMount, ref } from 'vue';

const props = defineProps<{
  alertTitle: string;
  alertBody?: string | undefined;
  buttonConfirmText: string;
  buttonCancelText: string;
  isShowCheckbox?: boolean | undefined;
  checkboxLabelString?: string | undefined;
  checkboxDefaultSelection?: boolean | undefined;
}>();

const checkboxOption = ref(false);
const { dialogRef, onDialogCancel, onDialogOK } = useDialogPluginComponent();

onBeforeMount(() => {
  checkboxOption.value = props.checkboxDefaultSelection ?? false;
});
</script>

<template>
  <q-dialog ref="dialogRef" persistent>
    <div class="tw-min-w-40 tw-content-center tw-bg-light-shade-1 tw-p-4">
      <div class="tw-mb-2 tw-text-xl tw-font-bold tw-text-primary-dark">{{ props.alertTitle }}</div>
      <div class="tw-mt-6 tw-text-base tw-font-semibold tw-text-dark-shade-4">{{ props.alertBody || '' }}</div>
      <q-checkbox
        v-if="props.isShowCheckbox"
        v-model="checkboxOption"
        color="primary"
        class="tw-mt-2 tw-text-base tw-font-semibold tw-text-primary-dark"
        dense
        :label="props.checkboxLabelString || ''"
      />
      <div class="tw-mt-4 tw-flex tw-grow tw-items-center tw-justify-end">
        <q-btn
          color="primary"
          flat
          no-caps
          :label="props.buttonCancelText"
          class="tw-px-4 tw-text-base tw-font-bold tw-text-primary-dark"
          @click="onDialogCancel"
        />
        <q-btn
          color="primary"
          flat
          no-caps
          :label="props.buttonConfirmText"
          class="tw-px-4 tw-text-base tw-font-bold tw-text-primary-dark"
          @click="onDialogOK(checkboxOption)"
        />
      </div>
    </div>
  </q-dialog>
</template>
