import axios, { AxiosInstance } from 'axios';
import {
  AuthApi,
  C1Api,
  C1BusinessAreaApi,
  C1DepartmentApi,
  C1HealthScoreApi,
  C1MasterApi,
  C1ProductEnvironmentApi,
  C1TypeApi,
  C2Api,
  C2HealthScoreApi,
  C2TypeApi,
  C2TypeHealthScoreApi,
  Configuration,
  DataSeedApi,
  KPIApi,
  LocationApi,
  MembershipApi,
  OnboardingStepsApi,
  PasswordApi,
  ProfileApi,
  SitemapApi,
  StageApi,
  SubscriptionOptionApi,
  TaskApi,
  U1AggregationApi,
  U1Api,
  U1MasterApi,
  U1RoleApi,
  U2Api,
  U2HealthScoreApi,
  U2TypeHealthScoresApi,
  U2RoleApi,
  U2TypeApi,
  UploadApi,
  U2RoleLevelHealthScoresApi,
  InvitationApi,
  HelpRequestApi,
  MessageApi,
  AllU1AggregationApi,
  CSHandfeelLogsApi,
  TaskAssociationApi,
  PlaybookApi,
  CadenceApi,
  PlaybookTriggerConditionApi,
  PlaybookRemedialStepApi,
  ZapscaleUniversalObjectApi,
  PlaybookEmailApi,
  PlaybookTaskApi,
  PlaybookMessageApi,
  AppTypeApi,
  PeripheralAppApi,
  ActionTypeApi,
  ActionApi,
  AppTypePeripheralApi,
  AppPeripheralApi,
  AppSuspectC2Api,
  PlanApi,
  PlanOptionApi,
  AppSuspectC2MatchApi,
  C2SegmentApi,
  ProductApi,
  ProductSuspectsApi,
  C2ProductU1MappingApi,
  SubscriptionApi,
  PlaybookDisplayGroupsApi,
  C2SubscriptionApi,
  PaymentTrackingLogApi,
  C2FeatureDataApi,
  PagesApi,
  CsvIntegrationApi,
  AppEventsApi,
  PlaybookAudienceApi,
  PlaybookMessageStepApi,
  PlaybookC1EmailApi,
  PlaybookC1EmailStepApi,
  PlaybookC2EmailApi,
  PlaybookC2EmailStepApi,
  U2FeatureDataApi,
  SuccessPlanApi,
  MilestoneAutoActionApi,
  ZapscaleToAppObjectsFieldsMappingsApi,
  AppObjectsApi,
  AppObjectsFieldsApi,
  AppObject1ToObject2MapApi,
  ZapscaleToAppObjectsMappingsApi,
  AppUniqueFieldValuesApi,
  AppPeripheralStatusApi,
  ZapscaleAppObjectsApi,
  C2SuccessPlanLogApi,
  C2ProductDataApi,
  ZapscaleFieldConditionsApi,
  CustomKpiRuleApi,
  OutcomeSuccessApi,
  SuspectC2MatcherApi,
  PlanToProductMappingApi,
  C2DataSettingsApi,
  PersonalAccessTokenApi,
  AppCustomDataLogsApi,
  C2SegmentHealthScoreApi,
  CommSystemsAllDataApi,
  CommunicationLogApi,
  CSEmailApi,
  ProductUsageMappingApi,
  C2DocumentsApi,
  CustomFieldsDefinitionApi,
  InboxApi,
  ContactsApi,
  EventsApi,
  CampaignDocumentsApi,
  CampaignApi,
  CampaignTemplateApi,
  EmailQueueApi,
  ProcessorJobsApi,
  DashboardApi,
  TransformationChangeLogApi,
  CustomerCustomViewApi,
  AppNotificationsApi,
  SearchApi,
  PeripheralTasksApi,
  PeripheralNotesApi,
  PeripheralTaskPriorityApi,
  PeripheralTaskStatusApi,
  DomainApi,
  BlacklistUserEmailApi,
  WarehouseTransformationApi,
  WarehouseFieldDefinitionApi,
  FeatureFlagsApi,
  EmailQueueLogsApi,
  KpiConditionApi,
  CsvDownloadJobApi,
  DynamicFieldConfigApi,
  U2PersonaApi,
  CrmUpsellLogsApi,
  CrmRenewalLogsApi,
  C2SegmentShareApi,
  CsvPaymentTrackingLogApi,
  C2FilterUsageApi,
  ZsNotesApi,
  SurveysApi,
  PeripheralU1MasterApi,
  U2ContactsApi,
  UpsellDealsApi,
  RenewalDealsApi,
  ExchangeRateApi,
  DealForecastApi,
  TaskUpdateLogApi,
  ActionUpdateLogApi,
  C2DocumentTypesApi,
} from './client';
import { EmailLogApi } from './client/sdk/email-log-api';
import { TicketTrackingLogApi } from './client/sdk/ticket-tracking-log-api';

export type ServerError = {
  error: string;
  message: string;
  statusCode: number;
};

const basePath = import.meta.env.VITE_API_BASE_PATH as string;

const configuration = new Configuration({
  basePath: basePath,
});

export const axiosInstance = axios.create({
  withCredentials: true,
});

const apiOptions: [Configuration, string, AxiosInstance] = [configuration, basePath, axiosInstance];

export const authApi = new AuthApi(...apiOptions);

export const profileApi = new ProfileApi(...apiOptions);

export const membershipApi = new MembershipApi(...apiOptions);

export const c1MasterApi = new C1MasterApi(...apiOptions);

export const c1BusinessApi = new C1BusinessAreaApi(...apiOptions);

export const c1TypeApi = new C1TypeApi(...apiOptions);

export const c2HealthScoreApi = new C2HealthScoreApi(...apiOptions);

export const c2Api = new C2Api(...apiOptions);

export const c2TypeApi = new C2TypeApi(...apiOptions);

export const dataSeedApi = new DataSeedApi(...apiOptions);

export const u1Api = new U1Api(...apiOptions);

export const u1RoleApi = new U1RoleApi(...apiOptions);

export const c1DepartmentApi = new C1DepartmentApi(...apiOptions);

export const c1BusinessAreaApi = new C1BusinessAreaApi(...apiOptions);

export const locationApi = new LocationApi(...apiOptions);

export const stageApi = new StageApi(...apiOptions);

export const c1Api = new C1Api(...apiOptions);

export const onboardingStepsApi = new OnboardingStepsApi(...apiOptions);

export const u2Api = new U2Api(...apiOptions);

export const u2RoleApi = new U2RoleApi(...apiOptions);

export const u2TypeApi = new U2TypeApi(...apiOptions);

export const c2TypeHealthScoreApi = new C2TypeHealthScoreApi(...apiOptions);

export const c1HealthScoreApi = new C1HealthScoreApi(...apiOptions);

export const passwordApi = new PasswordApi(...apiOptions);

export const uploadApi = new UploadApi(...apiOptions);

export const sitemapApi = new SitemapApi(...apiOptions);

export const c1ProductEnvironmentApi = new C1ProductEnvironmentApi(...apiOptions);

export const subscriptionOptionApi = new SubscriptionOptionApi(...apiOptions);

export const kpiApi = new KPIApi(...apiOptions);

export const u1MasterApi = new U1MasterApi(...apiOptions);

export const invitationApi = new InvitationApi(...apiOptions);

export const u2HealthScoreApi = new U2HealthScoreApi(...apiOptions);

export const u2TypeHealthScoresApi = new U2TypeHealthScoresApi(...apiOptions);

export const u1AggregationApi = new U1AggregationApi(...apiOptions);

export const allU1AggregationApi = new AllU1AggregationApi(...apiOptions);

export const taskApi = new TaskApi(...apiOptions);

export const u2RoleLevelHealthScoresApi = new U2RoleLevelHealthScoresApi(...apiOptions);

export const helpRequestApi = new HelpRequestApi(...apiOptions);

export const messageApi = new MessageApi(...apiOptions);

export const csHandfeelLogsApi = new CSHandfeelLogsApi(...apiOptions);

export const taskAssociationApi = new TaskAssociationApi(...apiOptions);

export const playbookApi = new PlaybookApi(...apiOptions);

export const cadenceApi = new CadenceApi(...apiOptions);

export const playbookTriggerConditionApi = new PlaybookTriggerConditionApi(...apiOptions);

export const zapscaleUniversalObjectApi = new ZapscaleUniversalObjectApi(...apiOptions);

export const playbookTaskApi = new PlaybookTaskApi(...apiOptions);

export const playbookRemedialStepApi = new PlaybookRemedialStepApi(...apiOptions);

export const playbookMessageApi = new PlaybookMessageApi(...apiOptions);

export const playbookEmailApi = new PlaybookEmailApi(...apiOptions);

export const appTypeApi = new AppTypeApi(...apiOptions);

export const peripheralAppApi = new PeripheralAppApi(...apiOptions);

export const actionTypeApi = new ActionTypeApi(...apiOptions);

export const actionApi = new ActionApi(...apiOptions);

export const appPeripheralApi = new AppPeripheralApi(...apiOptions);

export const appTypePeripheralApi = new AppTypePeripheralApi(...apiOptions);

export const appSuspectC2Api = new AppSuspectC2Api(...apiOptions);

export const planApi = new PlanApi(...apiOptions);

export const planOptionApi = new PlanOptionApi(...apiOptions);

export const appSuspectC2MatchApi = new AppSuspectC2MatchApi(...apiOptions);

export const c2SegmentApi = new C2SegmentApi(...apiOptions);

export const productApi = new ProductApi(...apiOptions);

export const productSuspectsApi = new ProductSuspectsApi(...apiOptions);

export const c2ProductU1MappingApi = new C2ProductU1MappingApi(...apiOptions);

export const subscriptionApi = new SubscriptionApi(...apiOptions);

export const playbookDisplayGroupApi = new PlaybookDisplayGroupsApi(...apiOptions);

export const c2SubscriptionApi = new C2SubscriptionApi(...apiOptions);

export const ticketTrackingLogApi = new TicketTrackingLogApi(...apiOptions);

export const paymentTrackingLogApi = new PaymentTrackingLogApi(...apiOptions);

export const emailLogApi = new EmailLogApi(...apiOptions);

export const c2FeatureDataApi = new C2FeatureDataApi(...apiOptions);

export const pagesApi = new PagesApi(...apiOptions);

export const csvIntegrationApi = new CsvIntegrationApi(...apiOptions);

export const appEventsApi = new AppEventsApi(...apiOptions);

export const playbookAudienceApi = new PlaybookAudienceApi(...apiOptions);

export const playbookMessageStepApi = new PlaybookMessageStepApi(...apiOptions);

export const playbookC1EmailApi = new PlaybookC1EmailApi(...apiOptions);

export const playbookC1EmailStepApi = new PlaybookC1EmailStepApi(...apiOptions);

export const playbookC2EmailApi = new PlaybookC2EmailApi(...apiOptions);

export const playbookC2EmailStepApi = new PlaybookC2EmailStepApi(...apiOptions);

export const u2FeatureDataApi = new U2FeatureDataApi(...apiOptions);

export const successPlanApi = new SuccessPlanApi(...apiOptions);

export const milestoneAutoActionApi = new MilestoneAutoActionApi(...apiOptions);

export const zapscaleToAppObjectsFieldsMappingsApi = new ZapscaleToAppObjectsFieldsMappingsApi(...apiOptions);

export const appObjectsApi = new AppObjectsApi(...apiOptions);

export const appObjectsFieldsApi = new AppObjectsFieldsApi(...apiOptions);

export const appObject1ToObject2MapApi = new AppObject1ToObject2MapApi(...apiOptions);

export const zapscaleToAppObjectsMappingsApi = new ZapscaleToAppObjectsMappingsApi(...apiOptions);

export const appUniqueFieldValuesApi = new AppUniqueFieldValuesApi(...apiOptions);

export const appPeripheralStatusApi = new AppPeripheralStatusApi(...apiOptions);

export const zapscaleAppObjectsApi = new ZapscaleAppObjectsApi(...apiOptions);

export const c2SuccessPlanLogApi = new C2SuccessPlanLogApi(...apiOptions);

export const c2ProductDataApi = new C2ProductDataApi(...apiOptions);

export const zapscaleFieldConditionsApi = new ZapscaleFieldConditionsApi(...apiOptions);

export const customKpiRuleApi = new CustomKpiRuleApi(...apiOptions);

export const outcomeSuccessApi = new OutcomeSuccessApi(...apiOptions);

export const suspectC2MatcherApi = new SuspectC2MatcherApi(...apiOptions);

export const planToProductMappingApi = new PlanToProductMappingApi(...apiOptions);

export const c2DataSettingsApi = new C2DataSettingsApi(...apiOptions);

export const personalAccessTokenApi = new PersonalAccessTokenApi(...apiOptions);

export const appCustomDataLogApi = new AppCustomDataLogsApi(...apiOptions);

export const c2SegmentHealthScoreApi = new C2SegmentHealthScoreApi(...apiOptions);

export const commSystemsAllDataApi = new CommSystemsAllDataApi(...apiOptions);

export const communicationLogApi = new CommunicationLogApi(...apiOptions);

export const cSEmailApi = new CSEmailApi(...apiOptions);

export const productUsageMappingApi = new ProductUsageMappingApi(...apiOptions);

export const c2DocumentsApi = new C2DocumentsApi(...apiOptions);

export const customFieldsDefinitionApi = new CustomFieldsDefinitionApi(...apiOptions);

export const inboxApi = new InboxApi(...apiOptions);

export const contacstApi = new ContactsApi(...apiOptions);

export const eventsApi = new EventsApi(...apiOptions);

export const campaignDocumentApi = new CampaignDocumentsApi(...apiOptions);

export const campaignApi = new CampaignApi(...apiOptions);

export const campaignTemplateApi = new CampaignTemplateApi(...apiOptions);

export const emailQueueApi = new EmailQueueApi(...apiOptions);

export const processorJobsApi = new ProcessorJobsApi(...apiOptions);

export const dashboardApi = new DashboardApi(...apiOptions);

export const transformationChangeLogApi = new TransformationChangeLogApi(...apiOptions);

export const customerCustomViewApi = new CustomerCustomViewApi(...apiOptions);

export const appNotificationsApi = new AppNotificationsApi(...apiOptions);

export const searchApi = new SearchApi(...apiOptions);

export const peripheralTaskApi = new PeripheralTasksApi(...apiOptions);

export const peripheralNoteApi = new PeripheralNotesApi(...apiOptions);

export const peripheralTaskPriorityApi = new PeripheralTaskPriorityApi(...apiOptions);

export const peripheralTaskStatusApi = new PeripheralTaskStatusApi(...apiOptions);

export const domainApi = new DomainApi(...apiOptions);

export const blacklistUserEmailApi = new BlacklistUserEmailApi(...apiOptions);

export const warehouseTransformationApi = new WarehouseTransformationApi(...apiOptions);

export const warehouseFieldDefinitionApi = new WarehouseFieldDefinitionApi(...apiOptions);

export const featureFlagsApi = new FeatureFlagsApi(...apiOptions);

export const emailQueueLogsApi = new EmailQueueLogsApi(...apiOptions);

export const kpiConditionApi = new KpiConditionApi(...apiOptions);

export const csvDownloadJobApi = new CsvDownloadJobApi(...apiOptions);

export const dynamicFieldConfigApi = new DynamicFieldConfigApi(...apiOptions);

export const u2PersonaApi = new U2PersonaApi(...apiOptions);

export const crmUpsellLogsApi = new CrmUpsellLogsApi(...apiOptions);

export const crmRenewalLogsApi = new CrmRenewalLogsApi(...apiOptions);

export const c2SegmentShareApi = new C2SegmentShareApi(...apiOptions);

export const csvPaymentTrackingLogApi = new CsvPaymentTrackingLogApi(...apiOptions);

export const c2FilterUsageApi = new C2FilterUsageApi(...apiOptions);

export const zsNotesApi = new ZsNotesApi(...apiOptions);

export const surveysApi = new SurveysApi(...apiOptions);

export const peripheralU1MasterApi = new PeripheralU1MasterApi(...apiOptions);

export const u2ContactsApi = new U2ContactsApi(...apiOptions);

export const upsellDealsApi = new UpsellDealsApi(...apiOptions);

export const renewalDealsApi = new RenewalDealsApi(...apiOptions);
export const exchangeRatesApi = new ExchangeRateApi(...apiOptions);

export const dealForecastApi = new DealForecastApi(...apiOptions);
export const taskUpdateLogApi = new TaskUpdateLogApi(...apiOptions);
export const actionUpdateLogApi = new ActionUpdateLogApi(...apiOptions);

export const c2DocumentTypesApi = new C2DocumentTypesApi(...apiOptions);
