/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Action } from '../model/';
// @ts-ignore
import { ActionFilterDto } from '../model/';
// @ts-ignore
import { CreateActionDto } from '../model/';
// @ts-ignore
import { CreateFromTaskDto } from '../model/';
// @ts-ignore
import { GenerateTextResponseDto } from '../model/';
// @ts-ignore
import { PaginatedActionDto } from '../model/';
// @ts-ignore
import { RoleBaseActionPaginatedDto } from '../model/';
// @ts-ignore
import { SourceEnum } from '../model/';
// @ts-ignore
import { UpdateActionDto } from '../model/';
/**
 * ActionApi - axios parameter creator
 * @export
 */
export const ActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Action
         * @param {CreateActionDto} createActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerCreate: async (createActionDto: CreateActionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createActionDto' is not null or undefined
            assertParamExists('actionsControllerCreate', 'createActionDto', createActionDto)
            const localVarPath = `/api/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Convert completed Tasks to Actions
         * @param {CreateFromTaskDto} createFromTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerCreateFromTask: async (createFromTaskDto: CreateFromTaskDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFromTaskDto' is not null or undefined
            assertParamExists('actionsControllerCreateFromTask', 'createFromTaskDto', createFromTaskDto)
            const localVarPath = `/api/actions/create-from-task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFromTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerDownloadActionInfoCsv: async (actionFilterDto: ActionFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionFilterDto' is not null or undefined
            assertParamExists('actionsControllerDownloadActionInfoCsv', 'actionFilterDto', actionFilterDto)
            const localVarPath = `/api/actions/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of actions filter with sorting by creation date
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFilter: async (actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionFilterDto' is not null or undefined
            assertParamExists('actionsControllerFilter', 'actionFilterDto', actionFilterDto)
            const localVarPath = `/api/actions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of action filter v2 with performance enhancement
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFilterV2: async (actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionFilterDto' is not null or undefined
            assertParamExists('actionsControllerFilterV2', 'actionFilterDto', actionFilterDto)
            const localVarPath = `/api/v2/actions/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of actions
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindAll: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('actionsControllerFindAll', 'source', source)
            const localVarPath = `/api/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['healthScoreId'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpiId'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of actions V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindAllV2: async (source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('actionsControllerFindAllV2', 'source', source)
            const localVarPath = `/api/v2/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (healthScoreId !== undefined) {
                localVarQueryParameter['healthScoreId'] = healthScoreId;
            }

            if (kpiId !== undefined) {
                localVarQueryParameter['kpiId'] = kpiId;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Action against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindOne: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionsControllerFindOne', 'id', id)
            const localVarPath = `/api/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch action count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindU1ActionCount: async (xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/actions/zapbook-action-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerGenerateText: async (topic: string, pointers: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('actionsControllerGenerateText', 'topic', topic)
            // verify required parameter 'pointers' is not null or undefined
            assertParamExists('actionsControllerGenerateText', 'pointers', pointers)
            const localVarPath = `/api/actions/generate-text`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (pointers !== undefined) {
                localVarQueryParameter['pointers'] = pointers;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove selected attachment from action
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerRemoveAttachment: async (id: string, index: number, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionsControllerRemoveAttachment', 'id', id)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('actionsControllerRemoveAttachment', 'index', index)
            const localVarPath = `/api/actions/remove-attachment/{id}/{index}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Soft delete action
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerSoftDeleteActionItem: async (id: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionsControllerSoftDeleteActionItem', 'id', id)
            const localVarPath = `/api/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Actions against ID
         * @param {string} id 
         * @param {UpdateActionDto} updateActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerUpdate: async (id: string, updateActionDto: UpdateActionDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionsControllerUpdate', 'id', id)
            // verify required parameter 'updateActionDto' is not null or undefined
            assertParamExists('actionsControllerUpdate', 'updateActionDto', updateActionDto)
            const localVarPath = `/api/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionApi - functional programming interface
 * @export
 */
export const ActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Action
         * @param {CreateActionDto} createActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerCreate(createActionDto: CreateActionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerCreate(createActionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Convert completed Tasks to Actions
         * @param {CreateFromTaskDto} createFromTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerCreateFromTask(createFromTaskDto: CreateFromTaskDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerCreateFromTask(createFromTaskDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerDownloadActionInfoCsv(actionFilterDto: ActionFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerDownloadActionInfoCsv(actionFilterDto, xTenantId, sortBy, descending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of actions filter with sorting by creation date
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFilter(actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedActionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFilter(actionFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of action filter v2 with performance enhancement
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFilterV2(actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseActionPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFilterV2(actionFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of actions
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedActionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of actions V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleBaseActionPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find Action against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFindOne(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFindOne(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch action count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerFindU1ActionCount(xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerFindU1ActionCount(xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTextResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerGenerateText(topic, pointers, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove selected attachment from action
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerRemoveAttachment(id: string, index: number, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerRemoveAttachment(id, index, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Soft delete action
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerSoftDeleteActionItem(id: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerSoftDeleteActionItem(id, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Actions against ID
         * @param {string} id 
         * @param {UpdateActionDto} updateActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionsControllerUpdate(id: string, updateActionDto: UpdateActionDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionsControllerUpdate(id, updateActionDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionApi - factory interface
 * @export
 */
export const ActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Action
         * @param {CreateActionDto} createActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerCreate(createActionDto: CreateActionDto, xTenantId?: string, options?: any): AxiosPromise<Action> {
            return localVarFp.actionsControllerCreate(createActionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Convert completed Tasks to Actions
         * @param {CreateFromTaskDto} createFromTaskDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerCreateFromTask(createFromTaskDto: CreateFromTaskDto, xTenantId?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.actionsControllerCreateFromTask(createFromTaskDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zapbook Action Details CSV Download
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {string} [sortBy] Optional field
         * @param {boolean} [descending] Optional field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerDownloadActionInfoCsv(actionFilterDto: ActionFilterDto, xTenantId?: string, sortBy?: string, descending?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.actionsControllerDownloadActionInfoCsv(actionFilterDto, xTenantId, sortBy, descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of actions filter with sorting by creation date
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFilter(actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<PaginatedActionDto> {
            return localVarFp.actionsControllerFilter(actionFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of action filter v2 with performance enhancement
         * @param {ActionFilterDto} actionFilterDto 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFilterV2(actionFilterDto: ActionFilterDto, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, options?: any): AxiosPromise<RoleBaseActionPaginatedDto> {
            return localVarFp.actionsControllerFilterV2(actionFilterDto, xTenantId, skip, limit, sortBy, descending, before, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of actions
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindAll(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<PaginatedActionDto> {
            return localVarFp.actionsControllerFindAll(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of actions V2
         * @param {SourceEnum} source 
         * @param {string} [xTenantId] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {string} [sortBy] 
         * @param {number} [descending] 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {string} [healthScoreId] 
         * @param {string} [kpiId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindAllV2(source: SourceEnum, xTenantId?: string, skip?: number, limit?: number, sortBy?: string, descending?: number, before?: string, after?: string, healthScoreId?: string, kpiId?: string, options?: any): AxiosPromise<RoleBaseActionPaginatedDto> {
            return localVarFp.actionsControllerFindAllV2(source, xTenantId, skip, limit, sortBy, descending, before, after, healthScoreId, kpiId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Action against ID
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindOne(id: string, xTenantId?: string, options?: any): AxiosPromise<Action> {
            return localVarFp.actionsControllerFindOne(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch action count
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerFindU1ActionCount(xTenantId?: string, options?: any): AxiosPromise<number> {
            return localVarFp.actionsControllerFindU1ActionCount(xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {string} pointers 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerGenerateText(topic: string, pointers: string, xTenantId?: string, options?: any): AxiosPromise<GenerateTextResponseDto> {
            return localVarFp.actionsControllerGenerateText(topic, pointers, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove selected attachment from action
         * @param {string} id 
         * @param {number} index 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerRemoveAttachment(id: string, index: number, xTenantId?: string, options?: any): AxiosPromise<Action> {
            return localVarFp.actionsControllerRemoveAttachment(id, index, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Soft delete action
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerSoftDeleteActionItem(id: string, xTenantId?: string, options?: any): AxiosPromise<Action> {
            return localVarFp.actionsControllerSoftDeleteActionItem(id, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Actions against ID
         * @param {string} id 
         * @param {UpdateActionDto} updateActionDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionsControllerUpdate(id: string, updateActionDto: UpdateActionDto, xTenantId?: string, options?: any): AxiosPromise<Action> {
            return localVarFp.actionsControllerUpdate(id, updateActionDto, xTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionsControllerCreate operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerCreateRequest
 */
export interface ActionApiActionsControllerCreateRequest {
    /**
     * 
     * @type {CreateActionDto}
     * @memberof ActionApiActionsControllerCreate
     */
    readonly createActionDto: CreateActionDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerCreate
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerCreateFromTask operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerCreateFromTaskRequest
 */
export interface ActionApiActionsControllerCreateFromTaskRequest {
    /**
     * 
     * @type {CreateFromTaskDto}
     * @memberof ActionApiActionsControllerCreateFromTask
     */
    readonly createFromTaskDto: CreateFromTaskDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerCreateFromTask
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerDownloadActionInfoCsv operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerDownloadActionInfoCsvRequest
 */
export interface ActionApiActionsControllerDownloadActionInfoCsvRequest {
    /**
     * 
     * @type {ActionFilterDto}
     * @memberof ActionApiActionsControllerDownloadActionInfoCsv
     */
    readonly actionFilterDto: ActionFilterDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerDownloadActionInfoCsv
     */
    readonly xTenantId?: string

    /**
     * Optional field
     * @type {string}
     * @memberof ActionApiActionsControllerDownloadActionInfoCsv
     */
    readonly sortBy?: string

    /**
     * Optional field
     * @type {boolean}
     * @memberof ActionApiActionsControllerDownloadActionInfoCsv
     */
    readonly descending?: boolean
}

/**
 * Request parameters for actionsControllerFilter operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFilterRequest
 */
export interface ActionApiActionsControllerFilterRequest {
    /**
     * 
     * @type {ActionFilterDto}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly actionFilterDto: ActionFilterDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilter
     */
    readonly after?: string
}

/**
 * Request parameters for actionsControllerFilterV2 operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFilterV2Request
 */
export interface ActionApiActionsControllerFilterV2Request {
    /**
     * 
     * @type {ActionFilterDto}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly actionFilterDto: ActionFilterDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFilterV2
     */
    readonly after?: string
}

/**
 * Request parameters for actionsControllerFindAll operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFindAllRequest
 */
export interface ActionApiActionsControllerFindAllRequest {
    /**
     * 
     * @type {SourceEnum}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAll
     */
    readonly kpiId?: string
}

/**
 * Request parameters for actionsControllerFindAllV2 operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFindAllV2Request
 */
export interface ActionApiActionsControllerFindAllV2Request {
    /**
     * 
     * @type {SourceEnum}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly source: SourceEnum

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly sortBy?: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly descending?: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly before?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly after?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly healthScoreId?: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindAllV2
     */
    readonly kpiId?: string
}

/**
 * Request parameters for actionsControllerFindOne operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFindOneRequest
 */
export interface ActionApiActionsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindOne
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindOne
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerFindU1ActionCount operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerFindU1ActionCountRequest
 */
export interface ActionApiActionsControllerFindU1ActionCountRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerFindU1ActionCount
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerGenerateText operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerGenerateTextRequest
 */
export interface ActionApiActionsControllerGenerateTextRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerGenerateText
     */
    readonly topic: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerGenerateText
     */
    readonly pointers: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerGenerateText
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerRemoveAttachment operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerRemoveAttachmentRequest
 */
export interface ActionApiActionsControllerRemoveAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerRemoveAttachment
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof ActionApiActionsControllerRemoveAttachment
     */
    readonly index: number

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerRemoveAttachment
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerSoftDeleteActionItem operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerSoftDeleteActionItemRequest
 */
export interface ActionApiActionsControllerSoftDeleteActionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerSoftDeleteActionItem
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerSoftDeleteActionItem
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for actionsControllerUpdate operation in ActionApi.
 * @export
 * @interface ActionApiActionsControllerUpdateRequest
 */
export interface ActionApiActionsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateActionDto}
     * @memberof ActionApiActionsControllerUpdate
     */
    readonly updateActionDto: UpdateActionDto

    /**
     * 
     * @type {string}
     * @memberof ActionApiActionsControllerUpdate
     */
    readonly xTenantId?: string
}

/**
 * ActionApi - object-oriented interface
 * @export
 * @class ActionApi
 * @extends {BaseAPI}
 */
export class ActionApi extends BaseAPI {
    /**
     * 
     * @summary Create Action
     * @param {ActionApiActionsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerCreate(requestParameters: ActionApiActionsControllerCreateRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerCreate(requestParameters.createActionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Convert completed Tasks to Actions
     * @param {ActionApiActionsControllerCreateFromTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerCreateFromTask(requestParameters: ActionApiActionsControllerCreateFromTaskRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerCreateFromTask(requestParameters.createFromTaskDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zapbook Action Details CSV Download
     * @param {ActionApiActionsControllerDownloadActionInfoCsvRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerDownloadActionInfoCsv(requestParameters: ActionApiActionsControllerDownloadActionInfoCsvRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerDownloadActionInfoCsv(requestParameters.actionFilterDto, requestParameters.xTenantId, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of actions filter with sorting by creation date
     * @param {ActionApiActionsControllerFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFilter(requestParameters: ActionApiActionsControllerFilterRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFilter(requestParameters.actionFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of action filter v2 with performance enhancement
     * @param {ActionApiActionsControllerFilterV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFilterV2(requestParameters: ActionApiActionsControllerFilterV2Request, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFilterV2(requestParameters.actionFilterDto, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of actions
     * @param {ActionApiActionsControllerFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFindAll(requestParameters: ActionApiActionsControllerFindAllRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFindAll(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of actions V2
     * @param {ActionApiActionsControllerFindAllV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFindAllV2(requestParameters: ActionApiActionsControllerFindAllV2Request, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFindAllV2(requestParameters.source, requestParameters.xTenantId, requestParameters.skip, requestParameters.limit, requestParameters.sortBy, requestParameters.descending, requestParameters.before, requestParameters.after, requestParameters.healthScoreId, requestParameters.kpiId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Action against ID
     * @param {ActionApiActionsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFindOne(requestParameters: ActionApiActionsControllerFindOneRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFindOne(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch action count
     * @param {ActionApiActionsControllerFindU1ActionCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerFindU1ActionCount(requestParameters: ActionApiActionsControllerFindU1ActionCountRequest = {}, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerFindU1ActionCount(requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionApiActionsControllerGenerateTextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerGenerateText(requestParameters: ActionApiActionsControllerGenerateTextRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerGenerateText(requestParameters.topic, requestParameters.pointers, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove selected attachment from action
     * @param {ActionApiActionsControllerRemoveAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerRemoveAttachment(requestParameters: ActionApiActionsControllerRemoveAttachmentRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerRemoveAttachment(requestParameters.id, requestParameters.index, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Soft delete action
     * @param {ActionApiActionsControllerSoftDeleteActionItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerSoftDeleteActionItem(requestParameters: ActionApiActionsControllerSoftDeleteActionItemRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerSoftDeleteActionItem(requestParameters.id, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Actions against ID
     * @param {ActionApiActionsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionApi
     */
    public actionsControllerUpdate(requestParameters: ActionApiActionsControllerUpdateRequest, options?: AxiosRequestConfig) {
        return ActionApiFp(this.configuration).actionsControllerUpdate(requestParameters.id, requestParameters.updateActionDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}
