export enum RouteName {
  Dashboard = 'Dashboard',
  Dashboard_2 = 'Dashboard_2',
  AuthResponse = 'AuthResponse',
  Register = 'Register',
  Login = 'Login',
  SsoLoginVerify = 'SsoLoginVerify',
  SsoRegisterVerify = 'SsoRegisterVerify',
  Logout = 'Logout',
  ResetPasswordRequest = 'ResetPasswordRequest',
  ResetPassword = 'ResetPassword',
  Onboarding = 'Onboarding',
  Profile = 'Profile',
  BusinessInfo = 'BusinessInfo',
  ZapscaleUsers = 'ZapscaleUsers',
  ZapscaleUserList = 'ZapscaleUserList',
  ZapscaleNewInvitation = 'ZapscaleNewInvitation',
  OrganizationTree = 'OrganizationTree',
  SaasApp = 'SaasApp',
  SaasAppSiteMap = 'SaasAppSiteMap',
  SaasAppProducts = 'SaasAppProducts',
  SaasAppNewProduct = 'SaasAppNewProduct',
  SaasAppEditProduct = 'SaasAppEditProduct',
  SaasAppDomains = 'SaasAppDomains',
  SaasAppNewDomain = 'SaasAppNewDomain',
  SaasAppEditDomain = 'SaasAppEditDomain',
  SaasAppTrackerCode = 'SaasAppTrackerCode',
  SaasAppEvents = 'SaasAppEvents',
  SaasAppUserObject = 'SaasAppUserObject',
  UsageAndOutcomeKpis = 'UsageAndOutcomeKpis',
  PeripheralApps = 'PeripheralApps',
  PeripheralAppsCrm = 'PeripheralAppsCrm',
  PeripheralAppsTicketing = 'PeripheralAppsTicketing',
  PeripheralAppsEmailSystem = 'PeripheralAppsEmailSystem',
  PeripheralAppsCommSystem = 'PeripheralAppsCommSystem',
  PeripheralAppsFeatureRequests = 'PeripheralAppsFeatureRequests',
  PeripheralAppsPaymentSystem = 'PeripheralAppsPaymentSystem',
  PeripheralAppsProductUsage = 'PeripheralAppsProductUsage',
  PeripheralAppsDataWarehouse = 'PeripheralAppsDataWarehouse',
  PeripheralAppsKpisAndPlaybooks = 'PeripheralAppsKpisAndPlaybooks',
  UpsellSettings = 'UpsellSettings',
  CommercialPlanSettings = 'CommercialPlanSettings',
  UpsellKpiSettings = 'UpsellKpiSettings',
  CustomersAndUsers = 'CustomersAndUsers',
  SaasAppCustomers = 'SaasAppCustomers',
  SaasAppUsers = 'SaasAppUsers',
  SetCustomerTypes = 'SetCustomerTypes',
  SetCommercialPlans = 'SetCommercialPlans',
  AssignCsmToCustomers = 'AssignCsmToCustomers',
  SetUserTypes = 'SetUserTypes',
  CampaignExclusions = 'CampaignExclusions',
  CampaignEmailSettings = 'CampaignEmailSettings',
  Customers = 'Customers',
  Customer = 'Customer',
  AcceptInvitation = 'AcceptInvitation',
  Users = 'Users',
  CustomerOverview = 'CustomerOverview',
  CustomerKpis = 'CustomerKpis',
  CustomerTasks = 'CustomerTasks',
  CustomerMessages = 'CustomerMessages',
  CustomerActions = 'CustomerActions',
  CustomerInsights = 'CustomerInsights',
  CustomerSuccessPlans = 'CustomerSuccessPlans',
  CustomerDocuments = 'CustomerDocuments',
  CustomerNotes = 'CustomerNotes',
  CustomerKpiUsage = 'CustomerKpiUsage',
  CustomerKpiOutcome = 'CustomerKpiOutcome',
  CustomerKpiPeripheral = 'CustomerKpiPeripheral',
  CustomerKpiUpsell = 'CustomerKpiUpsell',
  CustomerKpiHandfeel = 'CustomerKpiHandfeel',
  CustomerAllData = 'CustomerAllData',
  CustomerTicketingData = 'CustomerTicketingData',
  CustomerFeatureRequestData = 'CustomerFeatureRequestData',
  CustomerPaymentData = 'CustomerPaymentData',
  CustomerEmailData = 'CustomerEmailData',
  CustomerCommSystemsData = 'CustomerCommSystemsData',
  CustomerApiData = 'CustomerApiData',
  CustomerProductFeatureUseData = 'CustomerProductFeatureUseData',
  User = 'User',
  UserOverview = 'UserOverview',
  Zapbook = 'Zapbook',
  ZapbookTask = 'ZapbookTask',
  ZapbookV2Overview = 'ZapbookV2Overview',
  ZapbookV2Task = 'ZapbookV2Task',
  ZapbookV3Task = 'ZapbookV3Task',
  ZapbookMessage = 'ZapbookMessage',
  ZapbookV2Message = 'ZapbookV2Message',
  ZapbookActions = 'ZapbookActions',
  ZapbookV2Actions = 'ZapbookV2Actions',
  ZapbookV3Actions = 'ZapbookV3Actions',
  ZapbookInsights = 'ZapbookInsights',
  ZapbookPerformance = 'ZapbookPerformance',
  ZapbookV2Performance = 'ZapbookV2Performance',
  ZapbookSuccessPlan = 'ZapbookSuccessPlan',
  ZapbookV2SuccessPlan = 'ZapbookV2SuccessPlan',
  ZapbookV2Notes = 'ZapbookV2Notes',
  UserHealthKpis = 'UserHealthKpis',
  UserHealthKpisUsage = 'UserHealthKpisUsage',
  UserHealthKpisOutcome = 'UserHealthKpisOutcome',
  UserAllData = 'UserAllData',
  UserFeatureData = 'UserFeatureData',
  AcceptHelpRequest = 'AcceptHelpRequest',
  UserAction = 'UserAction',
  UserTask = 'UserTask',
  UserMessage = 'UserMessage',
  CustomerMapping = 'CustomerMapping',
  VerifyEmail = 'VerifyEmail',
  ProductMapping = 'ProductMapping',
  Playbooks = 'Playbooks',
  PlaybookDisplayGroups = 'PlaybookDisplayGroups',
  SuccessPlan = 'SuccessPlan',
  SuccessPlanTab = 'SuccessPlanTab',
  PeripheralOAuthCallback = 'PeripheralOAuthCallback',
  CsHandfeelKpis = 'CsHandfeelKpis',
  NewUsageKpi = 'CreateUsageKpi',
  NewPeripheralKpi = 'CreatePeripheralKpi',
  NewUpsellKpi = 'CreateUpsellKpi',
  InboxList = 'InboxList',
  Inbox = 'Inbox',
  InboxThreadList = 'InboxThreadList',
  InboxThread = 'InboxThread',
  InboxConnect = 'InboxConnect',
  InboxConnectNew = 'InboxConnectNew',
  InboxOAuth2Verify = 'InboxOAuth2Verify',
  InboxSettings = 'InboxSettings',
  InboxSyncStatus = 'InboxSyncStatus',
  InboxSearch = 'InboxSearch',
  InboxSearchThread = 'InboxSearchThread',
  InboxConsent = 'InboxConsent',
  CampaignsV1 = 'Campaign',
  CampaignsList = 'CampaignList',
  CampaignTemplates = 'CampaignTemplates',
  CampaignDocuments = 'CampaignDocuments',
  Manage = 'Manage',
  ManageUserProfile = 'UserProfile',
  ManageDeveloperSettings = 'DeveloperSettings',
  ZapbookTaskDetail = 'ZapbookTaskDetail',
  ZapbookNoteDetail = 'ZapbookNoteDetail',
  ZapbookMessageDetail = 'ZapbookMessageDetail',
  ZapbookActionDetail = 'ZapbookActionDetail',
  Search = 'Search',
  SegmentHealth = 'SegmentHealth',
  FeatureFlagList = 'FeatureFlagList',
  FeatureFlagDetail = 'FeatureFlagDetail',
  ZapbookUpsellDeals = 'ZapbookUpsellDeals',
  ZapbookRenewalDeals = 'ZapbookRenewalDeals',
  CustomerUpsellDeals = 'CustomerUpsellDeals',
  CustomerRenewalDeals = 'CustomerRenewalDeals',
  Surveys = 'Surveys',
  NewSurvey = 'NewSurvey',
  EditSurvey = 'EditSurvey',
  SurveyResponse = 'SurveyResponse',
  NpsSurveysOverview = 'NpsSurveysOverview',
  NpsSurveysDetails = 'NpsSurveysDetails',
  NpsSurveysCustomerDetails = 'NpsSurveysCustomerDetails',
  NpsSurveysUserDetails = 'NpsSurveysUserDetails',
  CsatSurveysOverview = 'CsatSurveysOverview',
  CsatSurveysDetails = 'CsatSurveysDetails',
  CsatSurveysCustomerDetails = 'CsatSurveysCustomerDetails',
  CsatSurveysUserDetails = 'CsatSurveysUserDetails',
  NpsSurveys = 'NpsSurveys',
  CsatSurveys = 'CsatSurveys',
  NpsSurvey = 'NpsSurvey',
  CsatSurvey = 'CsatSurvey',
  UserHealthScores = 'UserHealthScores',
  Contacts = 'Contacts',
  NpsSurveysList = 'NpsSurveysList',
  CsatSurveysList = 'CsatSurveysList',
  NpsSurveyStatistics = 'NpsSurveyStatistics',
  CsatSurveyStatistics = 'CsatSurveyStatistics',
  DealForecast = 'DealForecast',
}
