/* tslint:disable */
/* eslint-disable */
/**
 * ZapScale
 * Web API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Action } from '../model/';
// @ts-ignore
import { AppCustomDataLogs } from '../model/';
// @ts-ignore
import { C2 } from '../model/';
// @ts-ignore
import { C2ProductU1Mapping } from '../model/';
// @ts-ignore
import { CampaignTemplateSchema } from '../model/';
// @ts-ignore
import { SignedUrlDto } from '../model/';
// @ts-ignore
import { Task } from '../model/';
// @ts-ignore
import { U1Master } from '../model/';
// @ts-ignore
import { U2 } from '../model/';
// @ts-ignore
import { U2Contacts } from '../model/';
// @ts-ignore
import { UploadCampaignDocumentDto } from '../model/';
// @ts-ignore
import { UploadDocumentDto } from '../model/';
/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk upload gutfeel from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerBulkUploadGutFeel: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/bulk-upload-gutfeel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Task with attachments
         * @param {string} taskHeading 
         * @param {string} [xTenantId] 
         * @param {string} [type] 
         * @param {string} [source] 
         * @param {string} [priority] 
         * @param {string} [taskTopic] 
         * @param {string} [taskText] 
         * @param {string} [taskAssigneeComments] 
         * @param {string} [taskCoverageTargetDate] 
         * @param {string} [assigneeU1Ids] 
         * @param {string} [c2Id] 
         * @param {string} [c2AppId] 
         * @param {string} [c2TypeId] 
         * @param {string} [u2Id] 
         * @param {string} [u2AppId] 
         * @param {string} [u2RoleId] 
         * @param {string} [u2TypeId] 
         * @param {string} [taskTypeId] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreateTaskWithAttachment: async (taskHeading: string, xTenantId?: string, type?: string, source?: string, priority?: string, taskTopic?: string, taskText?: string, taskAssigneeComments?: string, taskCoverageTargetDate?: string, assigneeU1Ids?: string, c2Id?: string, c2AppId?: string, c2TypeId?: string, u2Id?: string, u2AppId?: string, u2RoleId?: string, u2TypeId?: string, taskTypeId?: string, files?: Array<any>, attachments?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskHeading' is not null or undefined
            assertParamExists('uploadsControllerCreateTaskWithAttachment', 'taskHeading', taskHeading)
            const localVarPath = `/api/uploads/create-task-with-attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (source !== undefined) { 
                localVarFormParams.append('source', source as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (taskTopic !== undefined) { 
                localVarFormParams.append('task_topic', taskTopic as any);
            }
    
            if (taskText !== undefined) { 
                localVarFormParams.append('task_text', taskText as any);
            }
    
            if (taskAssigneeComments !== undefined) { 
                localVarFormParams.append('task_assignee_comments', taskAssigneeComments as any);
            }
    
            if (taskCoverageTargetDate !== undefined) { 
                localVarFormParams.append('task_coverage_target_date', taskCoverageTargetDate as any);
            }
    
            if (assigneeU1Ids !== undefined) { 
                localVarFormParams.append('assignee_u1_ids', assigneeU1Ids as any);
            }
    
            if (c2Id !== undefined) { 
                localVarFormParams.append('c2_id', c2Id as any);
            }
    
            if (c2AppId !== undefined) { 
                localVarFormParams.append('c2_app_id', c2AppId as any);
            }
    
            if (c2TypeId !== undefined) { 
                localVarFormParams.append('c2_type_id', c2TypeId as any);
            }
    
            if (u2Id !== undefined) { 
                localVarFormParams.append('u2_id', u2Id as any);
            }
    
            if (u2AppId !== undefined) { 
                localVarFormParams.append('u2_app_id', u2AppId as any);
            }
    
            if (u2RoleId !== undefined) { 
                localVarFormParams.append('u2_role_id', u2RoleId as any);
            }
    
            if (u2TypeId !== undefined) { 
                localVarFormParams.append('u2_type_id', u2TypeId as any);
            }
    
            if (taskTypeId !== undefined) { 
                localVarFormParams.append('task_type_id', taskTypeId as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

                if (attachments) {
                localVarFormParams.append('attachments', attachments.join(COLLECTION_FORMATS.csv));
            }

    
            if (taskHeading !== undefined) { 
                localVarFormParams.append('task_heading', taskHeading as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Template
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreateTemplateBody: async (name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'name', name)
            // verify required parameter 'displayName' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'displayName', displayName)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'subject', subject)
            // verify required parameter 'subjectJson' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'subjectJson', subjectJson)
            // verify required parameter 'subjectHtml' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'subjectHtml', subjectHtml)
            // verify required parameter 'salutationField' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'salutationField', salutationField)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'priority', priority)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'body', body)
            // verify required parameter 'bodyJson' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'bodyJson', bodyJson)
            // verify required parameter 'bodyHtml' is not null or undefined
            assertParamExists('uploadsControllerCreateTemplateBody', 'bodyHtml', bodyHtml)
            const localVarPath = `/api/uploads/create-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (displayName !== undefined) { 
                localVarFormParams.append('display_name', displayName as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('subject', subject as any);
            }
    
            if (subjectJson !== undefined) { 
                localVarFormParams.append('subject_json', subjectJson as any);
            }
    
            if (subjectHtml !== undefined) { 
                localVarFormParams.append('subject_html', subjectHtml as any);
            }
    
            if (salutationField !== undefined) { 
                localVarFormParams.append('salutation_field', salutationField as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.append('body', body as any);
            }
    
            if (bodyJson !== undefined) { 
                localVarFormParams.append('body_json', bodyJson as any);
            }
    
            if (bodyHtml !== undefined) { 
                localVarFormParams.append('body_html', bodyHtml as any);
            }
    
            if (previewBody !== undefined) { 
                localVarFormParams.append('preview_body', previewBody as any);
            }
    
            if (previewBodyHtml !== undefined) { 
                localVarFormParams.append('preview_body_html', previewBodyHtml as any);
            }
    
            if (bodyPlaceholders !== undefined) { 
                localVarFormParams.append('body_placeholders', bodyPlaceholders as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (userDefined !== undefined) { 
                localVarFormParams.append('user_defined', userDefined as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get S3 url for images used in campaign templates
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerGetCampaignBodyImageUploadUrl: async (id: string, fileName: string, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadsControllerGetCampaignBodyImageUploadUrl', 'id', id)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('uploadsControllerGetCampaignBodyImageUploadUrl', 'fileName', fileName)
            const localVarPath = `/api/uploads/{id}/getCampaignBodyImageUploadUrl`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get signed url against valid key
         * @param {SignedUrlDto} signedUrlDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerGetPresignedUrl: async (signedUrlDto: SignedUrlDto, xTenantId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signedUrlDto' is not null or undefined
            assertParamExists('uploadsControllerGetPresignedUrl', 'signedUrlDto', signedUrlDto)
            const localVarPath = `/api/uploads/getPresignedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import C2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportC2: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/c2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Customer Custom Fields from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportC2CustomFields: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/c2-custom-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload CSM data from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportCSMData: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/csm-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import U2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportU2: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/u2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import U2 contacts from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportU2Contacts: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/u2-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set profile image
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerSetProfileImage: async (xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads/profile-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Action attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateActionAttachments: async (id: string, xTenantId?: string, files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadsControllerUpdateActionAttachments', 'id', id)
            const localVarPath = `/api/uploads/update-action/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Task attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTaskAttachments: async (id: string, xTenantId?: string, files?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadsControllerUpdateTaskAttachments', 'id', id)
            const localVarPath = `/api/uploads/update-task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Template by ID
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTemplateBody: async (id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'name', name)
            // verify required parameter 'displayName' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'displayName', displayName)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'subject', subject)
            // verify required parameter 'subjectJson' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'subjectJson', subjectJson)
            // verify required parameter 'subjectHtml' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'subjectHtml', subjectHtml)
            // verify required parameter 'salutationField' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'salutationField', salutationField)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'priority', priority)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'body', body)
            // verify required parameter 'bodyJson' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'bodyJson', bodyJson)
            // verify required parameter 'bodyHtml' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBody', 'bodyHtml', bodyHtml)
            const localVarPath = `/api/uploads/update-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (displayName !== undefined) { 
                localVarFormParams.append('display_name', displayName as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('subject', subject as any);
            }
    
            if (subjectJson !== undefined) { 
                localVarFormParams.append('subject_json', subjectJson as any);
            }
    
            if (subjectHtml !== undefined) { 
                localVarFormParams.append('subject_html', subjectHtml as any);
            }
    
            if (salutationField !== undefined) { 
                localVarFormParams.append('salutation_field', salutationField as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.append('body', body as any);
            }
    
            if (bodyJson !== undefined) { 
                localVarFormParams.append('body_json', bodyJson as any);
            }
    
            if (bodyHtml !== undefined) { 
                localVarFormParams.append('body_html', bodyHtml as any);
            }
    
            if (previewBody !== undefined) { 
                localVarFormParams.append('preview_body', previewBody as any);
            }
    
            if (previewBodyHtml !== undefined) { 
                localVarFormParams.append('preview_body_html', previewBodyHtml as any);
            }
    
            if (bodyPlaceholders !== undefined) { 
                localVarFormParams.append('body_placeholders', bodyPlaceholders as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (userDefined !== undefined) { 
                localVarFormParams.append('user_defined', userDefined as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Template by ID and send a Preview
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTemplateBodyWithPreview: async (id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'name', name)
            // verify required parameter 'displayName' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'displayName', displayName)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'subject', subject)
            // verify required parameter 'subjectJson' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'subjectJson', subjectJson)
            // verify required parameter 'subjectHtml' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'subjectHtml', subjectHtml)
            // verify required parameter 'salutationField' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'salutationField', salutationField)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'priority', priority)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'body', body)
            // verify required parameter 'bodyJson' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'bodyJson', bodyJson)
            // verify required parameter 'bodyHtml' is not null or undefined
            assertParamExists('uploadsControllerUpdateTemplateBodyWithPreview', 'bodyHtml', bodyHtml)
            const localVarPath = `/api/uploads/update-template-with-preview/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (displayName !== undefined) { 
                localVarFormParams.append('display_name', displayName as any);
            }
    
            if (subject !== undefined) { 
                localVarFormParams.append('subject', subject as any);
            }
    
            if (subjectJson !== undefined) { 
                localVarFormParams.append('subject_json', subjectJson as any);
            }
    
            if (subjectHtml !== undefined) { 
                localVarFormParams.append('subject_html', subjectHtml as any);
            }
    
            if (salutationField !== undefined) { 
                localVarFormParams.append('salutation_field', salutationField as any);
            }
    
            if (priority !== undefined) { 
                localVarFormParams.append('priority', priority as any);
            }
    
            if (body !== undefined) { 
                localVarFormParams.append('body', body as any);
            }
    
            if (bodyJson !== undefined) { 
                localVarFormParams.append('body_json', bodyJson as any);
            }
    
            if (bodyHtml !== undefined) { 
                localVarFormParams.append('body_html', bodyHtml as any);
            }
    
            if (previewBody !== undefined) { 
                localVarFormParams.append('preview_body', previewBody as any);
            }
    
            if (previewBodyHtml !== undefined) { 
                localVarFormParams.append('preview_body_html', previewBodyHtml as any);
            }
    
            if (bodyPlaceholders !== undefined) { 
                localVarFormParams.append('body_placeholders', bodyPlaceholders as any);
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (userDefined !== undefined) { 
                localVarFormParams.append('user_defined', userDefined as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Document for Campaign
         * @param {string} fileType 
         * @param {string} fileDownloadLink 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUploadCampaignDocument: async (fileType: string, fileDownloadLink: string, xTenantId?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('uploadsControllerUploadCampaignDocument', 'fileType', fileType)
            // verify required parameter 'fileDownloadLink' is not null or undefined
            assertParamExists('uploadsControllerUploadCampaignDocument', 'fileDownloadLink', fileDownloadLink)
            const localVarPath = `/api/uploads/campaign-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (fileType !== undefined) { 
                localVarFormParams.append('file_type', fileType as any);
            }
    
            if (fileDownloadLink !== undefined) { 
                localVarFormParams.append('file_download_link', fileDownloadLink as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Document of type PDF,Text,DOCX or DOC
         * @param {string} fileTag 
         * @param {string} fileType 
         * @param {string} c2Id 
         * @param {string} createdBy 
         * @param {string} updatedBy 
         * @param {string} fileDownloadLink 
         * @param {string} fileDescriptionJSON 
         * @param {string} fileDescriptionHTML 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [docFileTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUploadDocument: async (fileTag: string, fileType: string, c2Id: string, createdBy: string, updatedBy: string, fileDownloadLink: string, fileDescriptionJSON: string, fileDescriptionHTML: string, xTenantId?: string, file?: any, docFileTypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileTag' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'fileTag', fileTag)
            // verify required parameter 'fileType' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'fileType', fileType)
            // verify required parameter 'c2Id' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'c2Id', c2Id)
            // verify required parameter 'createdBy' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'createdBy', createdBy)
            // verify required parameter 'updatedBy' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'updatedBy', updatedBy)
            // verify required parameter 'fileDownloadLink' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'fileDownloadLink', fileDownloadLink)
            // verify required parameter 'fileDescriptionJSON' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'fileDescriptionJSON', fileDescriptionJSON)
            // verify required parameter 'fileDescriptionHTML' is not null or undefined
            assertParamExists('uploadsControllerUploadDocument', 'fileDescriptionHTML', fileDescriptionHTML)
            const localVarPath = `/api/uploads/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xTenantId !== undefined && xTenantId !== null) {
                localVarHeaderParameter['x-tenant-id'] = String(xTenantId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (fileTag !== undefined) { 
                localVarFormParams.append('file_tag', fileTag as any);
            }
    
            if (fileType !== undefined) { 
                localVarFormParams.append('file_type', fileType as any);
            }
    
            if (c2Id !== undefined) { 
                localVarFormParams.append('c2_id', c2Id as any);
            }
    
            if (createdBy !== undefined) { 
                localVarFormParams.append('created_by', createdBy as any);
            }
    
            if (updatedBy !== undefined) { 
                localVarFormParams.append('updated_by', updatedBy as any);
            }
    
            if (fileDownloadLink !== undefined) { 
                localVarFormParams.append('file_download_link', fileDownloadLink as any);
            }
    
            if (fileDescriptionJSON !== undefined) { 
                localVarFormParams.append('file_description_JSON', fileDescriptionJSON as any);
            }
    
            if (fileDescriptionHTML !== undefined) { 
                localVarFormParams.append('file_description_HTML', fileDescriptionHTML as any);
            }
    
            if (docFileTypeId !== undefined) { 
                localVarFormParams.append('doc_file_type_id', docFileTypeId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk upload gutfeel from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerBulkUploadGutFeel(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerBulkUploadGutFeel(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Task with attachments
         * @param {string} taskHeading 
         * @param {string} [xTenantId] 
         * @param {string} [type] 
         * @param {string} [source] 
         * @param {string} [priority] 
         * @param {string} [taskTopic] 
         * @param {string} [taskText] 
         * @param {string} [taskAssigneeComments] 
         * @param {string} [taskCoverageTargetDate] 
         * @param {string} [assigneeU1Ids] 
         * @param {string} [c2Id] 
         * @param {string} [c2AppId] 
         * @param {string} [c2TypeId] 
         * @param {string} [u2Id] 
         * @param {string} [u2AppId] 
         * @param {string} [u2RoleId] 
         * @param {string} [u2TypeId] 
         * @param {string} [taskTypeId] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerCreateTaskWithAttachment(taskHeading: string, xTenantId?: string, type?: string, source?: string, priority?: string, taskTopic?: string, taskText?: string, taskAssigneeComments?: string, taskCoverageTargetDate?: string, assigneeU1Ids?: string, c2Id?: string, c2AppId?: string, c2TypeId?: string, u2Id?: string, u2AppId?: string, u2RoleId?: string, u2TypeId?: string, taskTypeId?: string, files?: Array<any>, attachments?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerCreateTaskWithAttachment(taskHeading, xTenantId, type, source, priority, taskTopic, taskText, taskAssigneeComments, taskCoverageTargetDate, assigneeU1Ids, c2Id, c2AppId, c2TypeId, u2Id, u2AppId, u2RoleId, u2TypeId, taskTypeId, files, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Template
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerCreateTemplateBody(name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerCreateTemplateBody(name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get S3 url for images used in campaign templates
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerGetCampaignBodyImageUploadUrl(id: string, fileName: string, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerGetCampaignBodyImageUploadUrl(id, fileName, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get signed url against valid key
         * @param {SignedUrlDto} signedUrlDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerGetPresignedUrl(signedUrlDto: SignedUrlDto, xTenantId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerGetPresignedUrl(signedUrlDto, xTenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import C2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerImportC2(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerImportC2(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Customer Custom Fields from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerImportC2CustomFields(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppCustomDataLogs>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerImportC2CustomFields(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload CSM data from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerImportCSMData(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<C2ProductU1Mapping>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerImportCSMData(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import U2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerImportU2(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerImportU2(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import U2 contacts from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerImportU2Contacts(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<U2Contacts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerImportU2Contacts(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set profile image
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerSetProfileImage(xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<U1Master>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerSetProfileImage(xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Action attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUpdateActionAttachments(id: string, xTenantId?: string, files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Action>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUpdateActionAttachments(id, xTenantId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Task attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUpdateTaskAttachments(id: string, xTenantId?: string, files?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUpdateTaskAttachments(id, xTenantId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Template by ID
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUpdateTemplateBody(id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUpdateTemplateBody(id, name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Template by ID and send a Preview
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUpdateTemplateBodyWithPreview(id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignTemplateSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUpdateTemplateBodyWithPreview(id, name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Document for Campaign
         * @param {string} fileType 
         * @param {string} fileDownloadLink 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUploadCampaignDocument(fileType: string, fileDownloadLink: string, xTenantId?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadCampaignDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUploadCampaignDocument(fileType, fileDownloadLink, xTenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Document of type PDF,Text,DOCX or DOC
         * @param {string} fileTag 
         * @param {string} fileType 
         * @param {string} c2Id 
         * @param {string} createdBy 
         * @param {string} updatedBy 
         * @param {string} fileDownloadLink 
         * @param {string} fileDescriptionJSON 
         * @param {string} fileDescriptionHTML 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [docFileTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUploadDocument(fileTag: string, fileType: string, c2Id: string, createdBy: string, updatedBy: string, fileDownloadLink: string, fileDescriptionJSON: string, fileDescriptionHTML: string, xTenantId?: string, file?: any, docFileTypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadsControllerUploadDocument(fileTag, fileType, c2Id, createdBy, updatedBy, fileDownloadLink, fileDescriptionJSON, fileDescriptionHTML, xTenantId, file, docFileTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk upload gutfeel from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerBulkUploadGutFeel(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.uploadsControllerBulkUploadGutFeel(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Task with attachments
         * @param {string} taskHeading 
         * @param {string} [xTenantId] 
         * @param {string} [type] 
         * @param {string} [source] 
         * @param {string} [priority] 
         * @param {string} [taskTopic] 
         * @param {string} [taskText] 
         * @param {string} [taskAssigneeComments] 
         * @param {string} [taskCoverageTargetDate] 
         * @param {string} [assigneeU1Ids] 
         * @param {string} [c2Id] 
         * @param {string} [c2AppId] 
         * @param {string} [c2TypeId] 
         * @param {string} [u2Id] 
         * @param {string} [u2AppId] 
         * @param {string} [u2RoleId] 
         * @param {string} [u2TypeId] 
         * @param {string} [taskTypeId] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreateTaskWithAttachment(taskHeading: string, xTenantId?: string, type?: string, source?: string, priority?: string, taskTopic?: string, taskText?: string, taskAssigneeComments?: string, taskCoverageTargetDate?: string, assigneeU1Ids?: string, c2Id?: string, c2AppId?: string, c2TypeId?: string, u2Id?: string, u2AppId?: string, u2RoleId?: string, u2TypeId?: string, taskTypeId?: string, files?: Array<any>, attachments?: Array<string>, options?: any): AxiosPromise<Task> {
            return localVarFp.uploadsControllerCreateTaskWithAttachment(taskHeading, xTenantId, type, source, priority, taskTopic, taskText, taskAssigneeComments, taskCoverageTargetDate, assigneeU1Ids, c2Id, c2AppId, c2TypeId, u2Id, u2AppId, u2RoleId, u2TypeId, taskTypeId, files, attachments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Template
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreateTemplateBody(name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.uploadsControllerCreateTemplateBody(name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get S3 url for images used in campaign templates
         * @param {string} id 
         * @param {string} fileName 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerGetCampaignBodyImageUploadUrl(id: string, fileName: string, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.uploadsControllerGetCampaignBodyImageUploadUrl(id, fileName, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get signed url against valid key
         * @param {SignedUrlDto} signedUrlDto 
         * @param {string} [xTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerGetPresignedUrl(signedUrlDto: SignedUrlDto, xTenantId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.uploadsControllerGetPresignedUrl(signedUrlDto, xTenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import C2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportC2(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<C2>> {
            return localVarFp.uploadsControllerImportC2(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Customer Custom Fields from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportC2CustomFields(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<AppCustomDataLogs>> {
            return localVarFp.uploadsControllerImportC2CustomFields(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload CSM data from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportCSMData(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<C2ProductU1Mapping>> {
            return localVarFp.uploadsControllerImportCSMData(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import U2 from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportU2(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<U2>> {
            return localVarFp.uploadsControllerImportU2(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import U2 contacts from CSV
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerImportU2Contacts(xTenantId?: string, file?: any, options?: any): AxiosPromise<Array<U2Contacts>> {
            return localVarFp.uploadsControllerImportU2Contacts(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set profile image
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerSetProfileImage(xTenantId?: string, file?: any, options?: any): AxiosPromise<U1Master> {
            return localVarFp.uploadsControllerSetProfileImage(xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Action attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateActionAttachments(id: string, xTenantId?: string, files?: Array<any>, options?: any): AxiosPromise<Action> {
            return localVarFp.uploadsControllerUpdateActionAttachments(id, xTenantId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Task attachments
         * @param {string} id 
         * @param {string} [xTenantId] 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTaskAttachments(id: string, xTenantId?: string, files?: Array<any>, options?: any): AxiosPromise<Task> {
            return localVarFp.uploadsControllerUpdateTaskAttachments(id, xTenantId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Template by ID
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTemplateBody(id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.uploadsControllerUpdateTemplateBody(id, name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Template by ID and send a Preview
         * @param {string} id 
         * @param {string} name 
         * @param {string} displayName 
         * @param {string} subject 
         * @param {string} subjectJson 
         * @param {string} subjectHtml 
         * @param {string} salutationField 
         * @param {string} priority 
         * @param {string} body 
         * @param {string} bodyJson 
         * @param {string} bodyHtml 
         * @param {string} [xTenantId] 
         * @param {string} [previewBody] 
         * @param {string} [previewBodyHtml] 
         * @param {string} [bodyPlaceholders] 
         * @param {Array<any>} [files] 
         * @param {boolean} [userDefined] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdateTemplateBodyWithPreview(id: string, name: string, displayName: string, subject: string, subjectJson: string, subjectHtml: string, salutationField: string, priority: string, body: string, bodyJson: string, bodyHtml: string, xTenantId?: string, previewBody?: string, previewBodyHtml?: string, bodyPlaceholders?: string, files?: Array<any>, userDefined?: boolean, options?: any): AxiosPromise<CampaignTemplateSchema> {
            return localVarFp.uploadsControllerUpdateTemplateBodyWithPreview(id, name, displayName, subject, subjectJson, subjectHtml, salutationField, priority, body, bodyJson, bodyHtml, xTenantId, previewBody, previewBodyHtml, bodyPlaceholders, files, userDefined, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Document for Campaign
         * @param {string} fileType 
         * @param {string} fileDownloadLink 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUploadCampaignDocument(fileType: string, fileDownloadLink: string, xTenantId?: string, file?: any, options?: any): AxiosPromise<UploadCampaignDocumentDto> {
            return localVarFp.uploadsControllerUploadCampaignDocument(fileType, fileDownloadLink, xTenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Document of type PDF,Text,DOCX or DOC
         * @param {string} fileTag 
         * @param {string} fileType 
         * @param {string} c2Id 
         * @param {string} createdBy 
         * @param {string} updatedBy 
         * @param {string} fileDownloadLink 
         * @param {string} fileDescriptionJSON 
         * @param {string} fileDescriptionHTML 
         * @param {string} [xTenantId] 
         * @param {any} [file] 
         * @param {string} [docFileTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUploadDocument(fileTag: string, fileType: string, c2Id: string, createdBy: string, updatedBy: string, fileDownloadLink: string, fileDescriptionJSON: string, fileDescriptionHTML: string, xTenantId?: string, file?: any, docFileTypeId?: string, options?: any): AxiosPromise<UploadDocumentDto> {
            return localVarFp.uploadsControllerUploadDocument(fileTag, fileType, c2Id, createdBy, updatedBy, fileDownloadLink, fileDescriptionJSON, fileDescriptionHTML, xTenantId, file, docFileTypeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for uploadsControllerBulkUploadGutFeel operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerBulkUploadGutFeelRequest
 */
export interface UploadApiUploadsControllerBulkUploadGutFeelRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerBulkUploadGutFeel
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerBulkUploadGutFeel
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerCreateTaskWithAttachment operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerCreateTaskWithAttachmentRequest
 */
export interface UploadApiUploadsControllerCreateTaskWithAttachmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskHeading: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly source?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly priority?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskTopic?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskText?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskAssigneeComments?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskCoverageTargetDate?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly assigneeU1Ids?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly c2Id?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly c2AppId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly c2TypeId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly u2Id?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly u2AppId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly u2RoleId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly u2TypeId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly taskTypeId?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly files?: Array<any>

    /**
     * 
     * @type {Array<string>}
     * @memberof UploadApiUploadsControllerCreateTaskWithAttachment
     */
    readonly attachments?: Array<string>
}

/**
 * Request parameters for uploadsControllerCreateTemplateBody operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerCreateTemplateBodyRequest
 */
export interface UploadApiUploadsControllerCreateTemplateBodyRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly displayName: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly subjectJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly subjectHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly salutationField: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly priority: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly body: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly bodyJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly bodyHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly previewBody?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly previewBodyHtml?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly bodyPlaceholders?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly files?: Array<any>

    /**
     * 
     * @type {boolean}
     * @memberof UploadApiUploadsControllerCreateTemplateBody
     */
    readonly userDefined?: boolean
}

/**
 * Request parameters for uploadsControllerGetCampaignBodyImageUploadUrl operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerGetCampaignBodyImageUploadUrlRequest
 */
export interface UploadApiUploadsControllerGetCampaignBodyImageUploadUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerGetCampaignBodyImageUploadUrl
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerGetCampaignBodyImageUploadUrl
     */
    readonly fileName: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerGetCampaignBodyImageUploadUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for uploadsControllerGetPresignedUrl operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerGetPresignedUrlRequest
 */
export interface UploadApiUploadsControllerGetPresignedUrlRequest {
    /**
     * 
     * @type {SignedUrlDto}
     * @memberof UploadApiUploadsControllerGetPresignedUrl
     */
    readonly signedUrlDto: SignedUrlDto

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerGetPresignedUrl
     */
    readonly xTenantId?: string
}

/**
 * Request parameters for uploadsControllerImportC2 operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerImportC2Request
 */
export interface UploadApiUploadsControllerImportC2Request {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerImportC2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerImportC2
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerImportC2CustomFields operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerImportC2CustomFieldsRequest
 */
export interface UploadApiUploadsControllerImportC2CustomFieldsRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerImportC2CustomFields
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerImportC2CustomFields
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerImportCSMData operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerImportCSMDataRequest
 */
export interface UploadApiUploadsControllerImportCSMDataRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerImportCSMData
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerImportCSMData
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerImportU2 operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerImportU2Request
 */
export interface UploadApiUploadsControllerImportU2Request {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerImportU2
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerImportU2
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerImportU2Contacts operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerImportU2ContactsRequest
 */
export interface UploadApiUploadsControllerImportU2ContactsRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerImportU2Contacts
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerImportU2Contacts
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerSetProfileImage operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerSetProfileImageRequest
 */
export interface UploadApiUploadsControllerSetProfileImageRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerSetProfileImage
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerSetProfileImage
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerUpdateActionAttachments operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUpdateActionAttachmentsRequest
 */
export interface UploadApiUploadsControllerUpdateActionAttachmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateActionAttachments
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateActionAttachments
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerUpdateActionAttachments
     */
    readonly files?: Array<any>
}

/**
 * Request parameters for uploadsControllerUpdateTaskAttachments operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUpdateTaskAttachmentsRequest
 */
export interface UploadApiUploadsControllerUpdateTaskAttachmentsRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTaskAttachments
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTaskAttachments
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerUpdateTaskAttachments
     */
    readonly files?: Array<any>
}

/**
 * Request parameters for uploadsControllerUpdateTemplateBody operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUpdateTemplateBodyRequest
 */
export interface UploadApiUploadsControllerUpdateTemplateBodyRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly displayName: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly subjectJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly subjectHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly salutationField: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly priority: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly body: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly bodyJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly bodyHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly previewBody?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly previewBodyHtml?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly bodyPlaceholders?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly files?: Array<any>

    /**
     * 
     * @type {boolean}
     * @memberof UploadApiUploadsControllerUpdateTemplateBody
     */
    readonly userDefined?: boolean
}

/**
 * Request parameters for uploadsControllerUpdateTemplateBodyWithPreview operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUpdateTemplateBodyWithPreviewRequest
 */
export interface UploadApiUploadsControllerUpdateTemplateBodyWithPreviewRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly name: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly displayName: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly subjectJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly subjectHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly salutationField: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly priority: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly body: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly bodyJson: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly bodyHtml: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly previewBody?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly previewBodyHtml?: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly bodyPlaceholders?: string

    /**
     * 
     * @type {Array<any>}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly files?: Array<any>

    /**
     * 
     * @type {boolean}
     * @memberof UploadApiUploadsControllerUpdateTemplateBodyWithPreview
     */
    readonly userDefined?: boolean
}

/**
 * Request parameters for uploadsControllerUploadCampaignDocument operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUploadCampaignDocumentRequest
 */
export interface UploadApiUploadsControllerUploadCampaignDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadCampaignDocument
     */
    readonly fileType: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadCampaignDocument
     */
    readonly fileDownloadLink: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadCampaignDocument
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerUploadCampaignDocument
     */
    readonly file?: any
}

/**
 * Request parameters for uploadsControllerUploadDocument operation in UploadApi.
 * @export
 * @interface UploadApiUploadsControllerUploadDocumentRequest
 */
export interface UploadApiUploadsControllerUploadDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly fileTag: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly fileType: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly c2Id: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly createdBy: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly updatedBy: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly fileDownloadLink: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly fileDescriptionJSON: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly fileDescriptionHTML: string

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly xTenantId?: string

    /**
     * 
     * @type {any}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly file?: any

    /**
     * 
     * @type {string}
     * @memberof UploadApiUploadsControllerUploadDocument
     */
    readonly docFileTypeId?: string
}

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Bulk upload gutfeel from CSV
     * @param {UploadApiUploadsControllerBulkUploadGutFeelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerBulkUploadGutFeel(requestParameters: UploadApiUploadsControllerBulkUploadGutFeelRequest = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerBulkUploadGutFeel(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Task with attachments
     * @param {UploadApiUploadsControllerCreateTaskWithAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerCreateTaskWithAttachment(requestParameters: UploadApiUploadsControllerCreateTaskWithAttachmentRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerCreateTaskWithAttachment(requestParameters.taskHeading, requestParameters.xTenantId, requestParameters.type, requestParameters.source, requestParameters.priority, requestParameters.taskTopic, requestParameters.taskText, requestParameters.taskAssigneeComments, requestParameters.taskCoverageTargetDate, requestParameters.assigneeU1Ids, requestParameters.c2Id, requestParameters.c2AppId, requestParameters.c2TypeId, requestParameters.u2Id, requestParameters.u2AppId, requestParameters.u2RoleId, requestParameters.u2TypeId, requestParameters.taskTypeId, requestParameters.files, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Template
     * @param {UploadApiUploadsControllerCreateTemplateBodyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerCreateTemplateBody(requestParameters: UploadApiUploadsControllerCreateTemplateBodyRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerCreateTemplateBody(requestParameters.name, requestParameters.displayName, requestParameters.subject, requestParameters.subjectJson, requestParameters.subjectHtml, requestParameters.salutationField, requestParameters.priority, requestParameters.body, requestParameters.bodyJson, requestParameters.bodyHtml, requestParameters.xTenantId, requestParameters.previewBody, requestParameters.previewBodyHtml, requestParameters.bodyPlaceholders, requestParameters.files, requestParameters.userDefined, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get S3 url for images used in campaign templates
     * @param {UploadApiUploadsControllerGetCampaignBodyImageUploadUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerGetCampaignBodyImageUploadUrl(requestParameters: UploadApiUploadsControllerGetCampaignBodyImageUploadUrlRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerGetCampaignBodyImageUploadUrl(requestParameters.id, requestParameters.fileName, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get signed url against valid key
     * @param {UploadApiUploadsControllerGetPresignedUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerGetPresignedUrl(requestParameters: UploadApiUploadsControllerGetPresignedUrlRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerGetPresignedUrl(requestParameters.signedUrlDto, requestParameters.xTenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import C2 from CSV
     * @param {UploadApiUploadsControllerImportC2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerImportC2(requestParameters: UploadApiUploadsControllerImportC2Request = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerImportC2(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Customer Custom Fields from CSV
     * @param {UploadApiUploadsControllerImportC2CustomFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerImportC2CustomFields(requestParameters: UploadApiUploadsControllerImportC2CustomFieldsRequest = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerImportC2CustomFields(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload CSM data from CSV
     * @param {UploadApiUploadsControllerImportCSMDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerImportCSMData(requestParameters: UploadApiUploadsControllerImportCSMDataRequest = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerImportCSMData(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import U2 from CSV
     * @param {UploadApiUploadsControllerImportU2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerImportU2(requestParameters: UploadApiUploadsControllerImportU2Request = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerImportU2(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import U2 contacts from CSV
     * @param {UploadApiUploadsControllerImportU2ContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerImportU2Contacts(requestParameters: UploadApiUploadsControllerImportU2ContactsRequest = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerImportU2Contacts(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set profile image
     * @param {UploadApiUploadsControllerSetProfileImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerSetProfileImage(requestParameters: UploadApiUploadsControllerSetProfileImageRequest = {}, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerSetProfileImage(requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Action attachments
     * @param {UploadApiUploadsControllerUpdateActionAttachmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUpdateActionAttachments(requestParameters: UploadApiUploadsControllerUpdateActionAttachmentsRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUpdateActionAttachments(requestParameters.id, requestParameters.xTenantId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Task attachments
     * @param {UploadApiUploadsControllerUpdateTaskAttachmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUpdateTaskAttachments(requestParameters: UploadApiUploadsControllerUpdateTaskAttachmentsRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUpdateTaskAttachments(requestParameters.id, requestParameters.xTenantId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Template by ID
     * @param {UploadApiUploadsControllerUpdateTemplateBodyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUpdateTemplateBody(requestParameters: UploadApiUploadsControllerUpdateTemplateBodyRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUpdateTemplateBody(requestParameters.id, requestParameters.name, requestParameters.displayName, requestParameters.subject, requestParameters.subjectJson, requestParameters.subjectHtml, requestParameters.salutationField, requestParameters.priority, requestParameters.body, requestParameters.bodyJson, requestParameters.bodyHtml, requestParameters.xTenantId, requestParameters.previewBody, requestParameters.previewBodyHtml, requestParameters.bodyPlaceholders, requestParameters.files, requestParameters.userDefined, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Template by ID and send a Preview
     * @param {UploadApiUploadsControllerUpdateTemplateBodyWithPreviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUpdateTemplateBodyWithPreview(requestParameters: UploadApiUploadsControllerUpdateTemplateBodyWithPreviewRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUpdateTemplateBodyWithPreview(requestParameters.id, requestParameters.name, requestParameters.displayName, requestParameters.subject, requestParameters.subjectJson, requestParameters.subjectHtml, requestParameters.salutationField, requestParameters.priority, requestParameters.body, requestParameters.bodyJson, requestParameters.bodyHtml, requestParameters.xTenantId, requestParameters.previewBody, requestParameters.previewBodyHtml, requestParameters.bodyPlaceholders, requestParameters.files, requestParameters.userDefined, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Document for Campaign
     * @param {UploadApiUploadsControllerUploadCampaignDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUploadCampaignDocument(requestParameters: UploadApiUploadsControllerUploadCampaignDocumentRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUploadCampaignDocument(requestParameters.fileType, requestParameters.fileDownloadLink, requestParameters.xTenantId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Document of type PDF,Text,DOCX or DOC
     * @param {UploadApiUploadsControllerUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadsControllerUploadDocument(requestParameters: UploadApiUploadsControllerUploadDocumentRequest, options?: AxiosRequestConfig) {
        return UploadApiFp(this.configuration).uploadsControllerUploadDocument(requestParameters.fileTag, requestParameters.fileType, requestParameters.c2Id, requestParameters.createdBy, requestParameters.updatedBy, requestParameters.fileDownloadLink, requestParameters.fileDescriptionJSON, requestParameters.fileDescriptionHTML, requestParameters.xTenantId, requestParameters.file, requestParameters.docFileTypeId, options).then((request) => request(this.axios, this.basePath));
    }
}
